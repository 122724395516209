const styles = {
  filtersRow:{
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '0px 20px 0px 20px',
    backgroundColor: '#FFFFFF',
    height: '48px',
  },
  statusBtnsContainer: {
    display: 'flex',
    gap: '6px',
    overflowX: 'scroll'
  },
  statusIconBtn: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    whiteSpace: 'nowrap',
    width: 48,
    padding: '8px',
    cursor: 'pointer',
    borderRadius: '50%',
  },
  statusText: {
    fontSize: '14px',
    fontWeight: '500',
    color: '#4C4E6499',
    lineHeight: '24px',
    align: 'center',
  },
}

export default styles;
