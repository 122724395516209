import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import { useHistory } from 'react-router-dom';
import Axios from 'axios';
import moment from 'moment';

import {
  Avatar, Box, Button, Checkbox, ClickAwayListener, FormControlLabel, Grid, Grow, IconButton,
  Menu, MenuItem, MenuList, Paper, Popper, Select, Table, TableBody, TableCell, TableHead,
  TablePagination, TableRow, Tooltip, Typography
} from '@mui/material';
import {
  ArrowDropDown as ArrowDropDownIcon,
  ArrowDropUp as ArrowDropUpIcon,
  MoreVert as MoreVertIcon,
  HelpOutline as KBLinkIcon,
  ContentCut as SplitIcon,
  FilePresent as DontSplitIcon,
  ContentCopy as DuplicateIcon,
  DeleteOutline as DeleteIcon,
} from '@mui/icons-material';

import PreviewIcon from 'src/dashboard-new/icons/PreviewIcon';
import i18n from 'src/i18n';
import { CustomError, FieldType, DocType, SelectedDocType, UserDataType } from 'src/types';
import { useAuth } from 'src/hooks/useAuth';
import { useConfig } from 'src/hooks/useConfig';
import DeleteDocumentModal from 'src/document-edit/components/DeleteDocumentModal/DeleteDocumentModal';
import ChooseValidatorModal from 'src/document-edit/components/ChooseValidatorModal/ChooseValidatorModal';
import SplitMenu from 'src/documents-new/components/SplitMenu/SplitMenu';
import { appendContactSupport, axiosHeaders, getLocalisedErrorString, sendFeedback, truncateString } from 'src/utils/helpers';
import { StatusChangeRequest } from 'src/document-edit/documentTypes';
import StatusChangeBtn from 'src/document-edit/components/StatusChangeBtn/StatusChangeBtn';
import { getIconOnTrustScore, kbLinks } from 'src/dashboard-new/utils';
import { getIsError } from 'src/document-edit/utils';
import PreviewDocument from 'src/documents-new/components/PreviewDocument/PreviewDocument';
import DuplicateMenu from 'src/documents-new/components/DuplicateMenu/DuplicateMenu';
import styles from './style';

interface PropTypes {
  docs: DocType[];
  total: number;
  page: string;
  limit: string;
  fields: FieldType[];
  sortDirection: string;
  sortField: string;
  loading: boolean;
  status: string;
  query: string;
  time: string;
  supplierFilter: boolean;
  companyFilter: boolean;
  ownerFilter: boolean;
  startTime: string;
  endTime: string;
  failedToExport: boolean;
  selectedDocs: SelectedDocType[];
  displayDocs: DocType[];
  users: UserDataType[];
  chooseValidatorOpen: boolean;
  selectedValidator: UserDataType | null;
  showCheckboxDropdown: boolean;
  handlePageChange: (_event: React.MouseEvent<HTMLButtonElement> | null, _newPage: number) => void;
  handleLimitChange: (_event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => void;
  handleSort: (_field: string) => void;
  refreshDocuments: () => void;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
  handleSelectOneDoc: (
    _event: React.ChangeEvent<HTMLInputElement>,
    _assistantID: string,
    _status: number,
    _id: number,
    _exportedToGstock: boolean,
    _isDuplicate: boolean
  ) => void;
  setChooseValidatorOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setSelectedValidator: React.Dispatch<React.SetStateAction<UserDataType | null>>;
  handleChangeBulkStatus: (_to: number) => Promise<void>;
  handleSelectedDocsChange: (_newlySelectedInvoices: SelectedDocType[]) => void;
  fetchAllAsstIdsAndSelect: () => Promise<void>;
  handleCheckboxDropdownToggle: () => void;
  handleCheckboxDropdownClose: () => void;
}

const DocumentsTable = (props: PropTypes) => {
  const {
    docs,
    total,
    page,
    limit,
    fields,
    sortDirection,
    sortField,
    loading,
    status,
    query,
    time,
    supplierFilter,
    companyFilter,
    ownerFilter,
    startTime,
    endTime,
    failedToExport,
    selectedDocs,
    displayDocs,
    users,
    chooseValidatorOpen,
    selectedValidator,
    showCheckboxDropdown,
    handlePageChange,
    handleLimitChange,
    handleSort,
    refreshDocuments,
    setLoading,
    handleSelectOneDoc,
    setChooseValidatorOpen,
    setSelectedValidator,
    handleChangeBulkStatus,
    handleSelectedDocsChange,
    fetchAllAsstIdsAndSelect,
    handleCheckboxDropdownToggle,
    handleCheckboxDropdownClose,
  } = props;
  const { ready, t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const { API, BRAND_NAME, IS_BRAND_PROCYS } = useConfig();
  const { user } = useAuth();

  const today = new Date().setHours(0, 0, 0, 0) / 1000;

  const [validatorUsers, setValidatorUsers] = useState<UserDataType[]>([]);
  const [selectedAssistantID, setSelectedAssistantID] = useState<string>('');
  const [selectedDocID, setSelectedDocID] = useState<number>(-1);
  const [openDeleteConf, setOpenDeleteConf] = useState<boolean>(false);

  const checkboxDropdownRef = useRef(null);

  const [statusMenuOpen, setStatusMenuOpen] = useState<boolean>(false);
  const [statusChangeLoading, setStatusChangeLoading] = useState<boolean>(false);
  const [menuAnchorEl, setMenuAnchorEl] = useState<EventTarget & HTMLButtonElement | null>(null);

  const [splitMenuOpen, setSplitMenuOpen] = useState<boolean>(false);
  const [documentToSplit, setDocumentToSplit] = useState<any>({});
  const [showPreview, setShowPreview] = useState<boolean>(false);
  const [docToPreview, setDocToPreview] = useState<any>(null);
  const [previewIndex, setPreviewIndex] = useState<number>(0);


  const [duplicateMenuOpen, setDuplicateMenuOpen] = useState<boolean>(false);
  const [duplicateDoc, setDuplicateDoc] = useState<any>({});

  const isCdc = user?.customisations.includes('cdcDocumentView');

  const handleDocToPreview = (doc:any,index:number) =>{
    setDocToPreview(doc);
    setPreviewIndex(index);
    setShowPreview(true);
  }

  const handleClosePreview = () =>{
    setShowPreview(false);
  }

  const handleSplitMenuOpen = (doc: DocType) => {
    setDocumentToSplit(doc);
    setSplitMenuOpen(true);
  };

  const handleSplitMenuClose = () => {
    setSplitMenuOpen(false);
    setDocumentToSplit({});
  };

  const handleDuplicateMenuOpen = (doc: DocType) => {
    setDuplicateDoc(doc);
    setDuplicateMenuOpen(true);
  };

  const handleDuplicateMenuClose = () => {
    setDuplicateMenuOpen(false);
    setDuplicateDoc({});
  };

  const handleSelectAllDocsOnPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newSelectedDocs: SelectedDocType[] = event.target.checked
      ? displayDocs.map(
        (invoice) => ({
          assistantID: invoice.assistantID || '000000',
          status: invoice.status,
          id: invoice.id,
          isDuplicate: Boolean(invoice.isDuplicate),
          exportedToGstock: Boolean(invoice.exportedToGstock),
        })
      )
      : [];
      handleSelectedDocsChange(newSelectedDocs);
    handleCheckboxDropdownClose();
  };

  const getValidatorUsers = async () => {
    try {
      const url = `${API.getUsersByCompanies}${encodeURIComponent(user?.companyID || '')}?permission=invoice_validate`;
      const resp = await Axios.get(url, axiosHeaders(localStorage.getItem('PROCYS_accessToken')));
      if (resp.data.success && resp.data.data?.length > 0) {
        setValidatorUsers(resp.data.data.filter((u: UserDataType) => u.status === 'Active'));
        setSelectedValidator(resp.data.data[0]);
      }
    } catch (error) {
      setValidatorUsers([]);
    }
  };

  const handleSendToValidateStatusChange = (to: number, assistantID: string) => {
    if (assistantID === '000000' && selectedDocs.length > 0) {
      handleChangeBulkStatus(to);
    } else {
      handleStatusChange(to, assistantID || selectedAssistantID);
    }
  };

  const handleStatusChange = async (status: number, assistantID: string, owner?: string) => {
    if (status === 900) {
      setOpenDeleteConf(true);
      return;
    }

    if (status === 501 && !chooseValidatorOpen) {
      setChooseValidatorOpen(true);
      return;
    }
    if (chooseValidatorOpen) {
      setChooseValidatorOpen(false);
    }

    handleStatusMenuClose();
    setStatusChangeLoading(true);

    try {
      const body : StatusChangeRequest = {
        assistantID: parseInt(assistantID, 10),
        toStatus: status.toString()
      };

      if (status === 501 && selectedValidator?.email && selectedValidator.email !== owner) {
        body.assignee = selectedValidator.email;
      }

      const response = await Axios.post(
        API.documentStatus,
        body,
        axiosHeaders(localStorage.getItem('PROCYS_accessToken'))
      );
      if (response.data.success) {
        refreshDocuments();
        enqueueSnackbar(status === 501
          ? t('INVOICE_STATUS_CHANGE_TO_VALIDATE')
          : (user?.isDocunecta && status === 503) ? t('DOCUNECTA_EXPORT_SUCCESS')
            : t('PROCYS_STATUS_CHANGE_SUCCESS'), {
          variant: 'success',
          autoHideDuration: 5000
        });
        if (status === 501) {
          sendFeedback(API.feedbackLogs, 'send to validate', user?.email, user?.companyID);
        }
        if (status === 502) {
          sendFeedback(API.feedbackLogs, 'validate invoice', user?.email, user?.companyID);
        }
        if (status === 503) {
          sendFeedback(API.feedbackLogs, 'invoice export', user?.email, user?.companyID);
          const currentUrlParams = new URLSearchParams(window.location.search);
          if (!user?.exported || user?.exported === 0) {
            currentUrlParams.set('feedback-first-export', 'success');
            history.push(`${window.location.pathname}?${currentUrlParams.toString()}`);
          }
          if (user?.exported === 9) {
            currentUrlParams.set('feedback-tenth-export', 'success');
            history.push(`${window.location.pathname}?${currentUrlParams.toString()}`);
          }
        }
      }

      handleSelectedDocsChange([]);
      setStatusChangeLoading(false);
    } catch (e) {
      const error = e as CustomError;
      enqueueSnackbar(getLocalisedErrorString(error?.response?.data?.i18n, t)
        || appendContactSupport(window.config.support_email, t((user?.isDocunecta && status === 503)
          ? 'DOCUNECTA_EXPORT_FAILURE' : 'INVOICE_STATUS_CHANGE_FAILED'), t),
      {
        variant: 'error',
        autoHideDuration: 5000
      });
      setStatusChangeLoading(false);
    }
  };

  const handleStatusMenuClose = () => {
    setStatusMenuOpen(false);
    setSelectedDocID(-1);
    setSelectedAssistantID('');
  };

  const handleStatusMenuToggle = (id: number, asstID?: string) => {
    setSelectedDocID(id);
    if (asstID) setSelectedAssistantID(asstID);
    setStatusMenuOpen(!statusMenuOpen);
  };

  const handleOpenMenu = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>, id: number, asstID: string) => {
    event.stopPropagation();
    event.preventDefault();
    setSelectedDocID(id);
    setSelectedAssistantID(asstID);
    setMenuAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setSelectedDocID(-1);
    setSelectedAssistantID('');
    setMenuAnchorEl(null);
  };

  const gotoLogs = (id: string) => {
    history.push(`/documents/${id}/logs`);
  };

  const onClickMenuItem = (event: React.MouseEvent<HTMLLIElement, MouseEvent>, status: number, id: string) => {
    event.stopPropagation();
    event.preventDefault();
    if (status === 200) {
      window.open(
        kbLinks[BRAND_NAME]?.
          replace(
            '__LANG__',
            i18n.language.split('-')[0] !== 'en' ? `/${i18n.language.split('-')[0]}` : ''
          ), '_blank'
        )
    } else {
      gotoLogs(id);
    }
  };

  const handleChangeOwner = async (owner: string, id: string) => {
    try {
      const body = {
        assistantID: parseInt(id, 10),
        owner
      };

      const response = await Axios.post(
        API.documentOwner,
        body,
        axiosHeaders(localStorage.getItem('PROCYS_accessToken'))
      );
      if (response.data.success) {
        refreshDocuments();
        enqueueSnackbar(t('PROCYS_OWNER_CHANGE_SUCCESS'), {
          variant: 'success',
        });
      }
    } catch (e) {
      const error = e as CustomError;
      enqueueSnackbar(getLocalisedErrorString(error?.response?.data?.i18n, t)
      || appendContactSupport(window.config.support_email, t('PROCYS_OWNER_CHANGE_FAILED'), t), {
        variant: 'error',
      });
    }
  };

  useEffect(() => {
    getValidatorUsers();
  }, []);

  const calculateDocPage = (count: number) => (parseInt(page) * parseInt(limit)) + count + 1;

  const handleDocumentClick = (doc: DocType, i: number) => {
    if (loading || doc.status === 200 || doc.status === 201) {
      return;
    }
    if (doc.outOfLimit) {
      if (!user?.isSubCompany) history.push('/account/subscription');
    } else if (doc.status >= 16 && doc.assistantID) {
      const route = isCdc ? '/documents/cdc/' : '/doc/';
      history.push(
        `${route}${doc.assistantID}/edit?${status !== null
          ? `status=${status}` : ''}${query !== ''
          ? `&query=${query}` : ''}${time !== ''
          ? `&time=${time}` : ''}${page !== null
          ? `&page=${page}` : ''}${limit
          ? `&limit=${limit}` : ''}${sortField
          ? `&sort_by=${sortField}&order=${sortDirection}` : ''}${supplierFilter
          ? `&supplier_filter=${supplierFilter}` : ''}${companyFilter
          ? `&company_filter=${companyFilter}` : ''}${ownerFilter
          ? `&owner_filter=${ownerFilter}` : ''}${startTime !== ''
          ? `&start_time=${startTime}` : ''}${endTime !== ''
          ? `&end_time=${endTime}` : ''}${failedToExport
          ? '&failed_to_export=true' : ''}${selectedDocs.length
          ? '&documents_selected=true' : ''}&document_page=${calculateDocPage(i)}&total=${total}`
      );
    }
  };

  const handleDocumentSplit = async (doc: DocType, splitType: string) => {
    setLoading(true);
    try {
      const body = {
        documentID: doc.id.toString(),
        splitType,
        pageCount: '1',
        pageRange: [],
      };

      const response = await Axios.post(
        API.splitDocument,
        body,
        axiosHeaders(localStorage.getItem('PROCYS_accessToken'))
      );
      setLoading(false);
      if (response.data.success) {
        refreshDocuments();
        enqueueSnackbar(t('SPLIT_DOCUMENT_SUCCESS'), {
          variant: 'success',
        });
      }
    } catch (e) {
      const error = e as CustomError;
      setLoading(false);
      enqueueSnackbar(getLocalisedErrorString(error?.response?.data?.i18n, t)
      || appendContactSupport(window.config.support_email, t('SPLIT_DOCUMENT_FAILED'), t), {
        variant: 'error',
      });
    }
  };

  const handleProcessDocument = async (doc: DocType, action: string) => {
    setLoading(true);
    if (action === 'delete') {
      setSelectedDocID(doc.id);
      setOpenDeleteConf(true);
    } else {
      try {
        const body = {
          IDs: [doc.id]
        };
        const response = await Axios.post(
          API.processNoneDuplicate,
          body,
          axiosHeaders(localStorage.getItem('PROCYS_accessToken'))
        );
        if (response.data.success) {
          refreshDocuments();
          setLoading(false);
          enqueueSnackbar(t('DOCUMENT_SENT_TO_PROCESS_SUCCESS'), {
            variant: 'success',
          });
        }
      } catch (error) {
        setLoading(false);
        enqueueSnackbar(t('DOCUMENT_SENT_TO_PROCESS_FAILED'), {
          variant: 'error',
        });
      }
    }
  };

  const numOfColumns = () => {
    const activeColumns = fields.filter((f) => f.isActive);
    let len = activeColumns.length;
    if (activeColumns.some((f) => f.key === 'status')) {
      len -= 1;
    }
    if (activeColumns.some((f) => f.key === 'appUploadedDate')) {
      len -= 1;
    }
    return len;
  };

  const handleRootCheckboxChange = () => {
    if (total <= parseInt(limit)) {
      if (selectedDocs.length === displayDocs.length) {
        handleSelectedDocsChange([]);
      } else {
        handleSelectedDocsChange(displayDocs.map((doc) => {
          return {
            assistantID: doc.assistantID || '',
            status: doc.status,
            id: doc.id,
            exportedToGstock: Boolean(doc.exportedToGstock),
            isDuplicate: Boolean(doc.isDuplicate),
          };
        }));
      }
    } else {
      handleCheckboxDropdownToggle();
    }
  };

  const renderSplitRow = (doc: DocType) => (
    <TableCell colSpan={numOfColumns()} sx={styles.tableBodyCellCommon}>
      <Box sx={styles.splitRow}>
        {`${t('DOCUMENTS_TABLE_SPLIT_REQUEST', { document: truncateString(doc.fileName, 12) })}`}
        <Box>
          <Button
            sx={styles.splitBtn}
            onClick={() => handleSplitMenuOpen(doc)}
            startIcon={<SplitIcon sx={styles.splitIcon} />}
          >
            {t('INVOICE_SPLIT_REQUEST_CHECK_N_SPLIT')}
          </Button>
          <Button
            sx={styles.splitBtn}
            onClick={() => handleDocumentSplit(doc, 'no')}
            startIcon={<SplitIcon sx={styles.splitIcon} />}
          >
            {t('INVOICE_SPLIT_REQUEST_DONT_SPLIT')}
          </Button>
          <Button
            sx={styles.splitBtn}
            onClick={() => handleDocumentSplit(doc, 'fixed')}
            startIcon={<DontSplitIcon sx={{ ...styles.splitIcon, rotate: 'unset' }} />}
          >
            {t('INVOICE_SPLIT_REQUEST_SPLIT_PER_PAGE')}
          </Button>
        </Box>
      </Box>
    </TableCell>
  );

  const renderDuplicateRow = (doc: DocType) => (
    <TableCell colSpan={numOfColumns()} sx={styles.tableBodyCellCommon}>
      <Box sx={styles.splitRow}>
        {`${t('DOCUMENTS_TABLE_POSSIBLE_DUPLICATE', { document: truncateString(doc.fileName, 12) })}`}
        <Box>
          <Button
            sx={styles.splitBtn}
            onClick={() => handleDuplicateMenuOpen(doc)}
            startIcon={<DuplicateIcon sx={styles.duplicateIcon} />}
          >
            {t('INVOICE_DUPLICATE_VIEW')}
          </Button>
          <Button
            sx={styles.splitBtn}
            onClick={() => handleProcessDocument(doc, 'proceed')}
            startIcon={<DuplicateIcon sx={styles.duplicateIcon} />}
          >
            {t('INVOICE_DUPLICATE_NO_DUPLICATE')}
          </Button>
          <Button
            sx={styles.splitBtn}
            onClick={() => handleProcessDocument(doc, 'delete')}
            startIcon={<DeleteIcon sx={{ ...styles.duplicateIcon, color: '#FF4D49' }} />}
          >
            {t('INVOICE_DUPLICATE_DELETE')}
          </Button>
        </Box>
      </Box>
    </TableCell>
  );

  const renderTableBodyCell = (key: string, dataType: string, val: string | number | undefined, doc: DocType) => {
    switch (key) {
      case 'status':
        return (
          <StatusChangeBtn
            statusChangeLoading={statusChangeLoading && selectedAssistantID === doc.assistantID}
            doc={doc}
            documentStatus={doc.status}
            statusMenuOpen={statusMenuOpen && selectedAssistantID === doc.assistantID}
            handleStatusMenuToggle={handleStatusMenuToggle}
            handleStatusMenuClose={handleStatusMenuClose}
            handleStatusChange={handleStatusChange}
          />
        );

      case 'owner':
        return (
          <Select
            disabled={doc.outOfLimit}
            onChange={(e) => handleChangeOwner(e.target.value, doc.assistantID || '')}
            sx={styles.select}
            value={users.find((u) => u.email === doc.owner)?.email || ''}
            variant="outlined"
            MenuProps={{
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'left'
              },
              transformOrigin: {
                vertical: 'top',
                horizontal: 'left'
              },
              sx: styles.ownerMenuContainer
            }}
          >
            {users.map((u) => (
              <MenuItem key={u.email} value={u.email}>
                <Box
                  display="flex"
                  alignItems="center"
                >
                  <Avatar
                    variant="circular"
                    alt={u.firstname}
                    sx={styles.userImage}
                    src={
                      u.avatar && u.avatar.indexOf('data:image') >= 0
                        ? u.avatar : `${API.getProfileImage}${u.avatar}`
                    }
                  />
                  <Typography sx={styles.usernameText}>
                    {truncateString(`${u.firstname} ${u.lastname}`, 12)}
                  </Typography>
                </Box>
              </MenuItem>
            ))}
          </Select>
        );

      case 'appUploadedDate':
        {
          const uploadedDate = val as number;
          const isInvoiceToday = uploadedDate >= today;
          return (
            <Typography
              sx={{
                ...styles.uploadedDateTxt,
                cursor: (doc.status === 200 || doc.status === 201) ? 'default' : 'pointer'
              }}
            >
              {isInvoiceToday ? moment.unix(uploadedDate).format('hh:mm') : moment.unix(uploadedDate).format('MMM DD, YYYY')}
            </Typography>);
        }

      default:
        {
          const trustScore = (doc.trustScores && doc.trustScores[key]) ? doc.trustScores[key] : -1;
          const isError = val && typeof val === 'string' ? getIsError(key, dataType, val, user) : false;
          return (
            <Tooltip title={(val?.toString() && val?.toString().length > 16) ? val?.toString() : ''}>
              <Box sx={styles.defaultCellContent}>
                {getIconOnTrustScore(trustScore, isError, true)}
                {truncateString(val?.toString(), 16)}
              </Box>
            </Tooltip>
          );
        }
    }
  };

  return (
    <Grid sx={styles.root}>
      <Grid className="xy-scroll">
        <Table>
          <TableHead sx={styles.tableHead}>
            <TableRow sx={styles.tableHeadRow}>
              <TableCell sx={{ ...styles.tableCellCheckBoxContainer, ...styles.tableHeadCellCommon }}>
                <Checkbox
                  sx={styles.checkBox}
                  indeterminate={selectedDocs.length > 0 && selectedDocs.length < displayDocs.length}
                  checked={displayDocs.length > 0 && selectedDocs.length >= displayDocs.length}
                  onChange={handleRootCheckboxChange}
                />
                <IconButton disabled={total <= parseInt(limit)} onClick={handleRootCheckboxChange} ref={checkboxDropdownRef} sx={styles.dropDownIconContainer}>
                  <ArrowDropDownIcon sx={styles.sortIcon} />
                </IconButton>
                <Popper
                  open={showCheckboxDropdown}
                  anchorEl={checkboxDropdownRef.current}
                  transition
                  sx={styles.checkboxDropdown}
                >
                  {({ TransitionProps, placement }) => (
                    <Grow
                      {...TransitionProps}
                      style={{
                        transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
                      }}
                    >
                      <Paper>
                        <ClickAwayListener onClickAway={handleCheckboxDropdownClose}>
                          <MenuList id="status-change-menu" sx={styles.selectAllComponent} autoFocusItem>
                            <MenuItem>
                              <FormControlLabel
                                label={t('DASHBOARD_SELECT_ALL_ON_PAGE', { count: total < parseInt(limit) ? total : parseInt(limit) })}
                                sx={styles.formcontrolLabel}
                                control={
                                  <Checkbox
                                    sx={{ ...styles.checkBox, margin: '0 8px' }}
                                    checked={selectedDocs.length === parseInt(limit)}
                                    onChange={(e) => handleSelectAllDocsOnPage(e)}
                                  />
                                }
                              />
                            </MenuItem>
                            <MenuItem sx={styles.selectAllRow}>
                              <FormControlLabel
                                label={t('DASHBOARD_SELECT_ALL_ON_STATUS', { count: total })}
                                sx={styles.formcontrolLabel}
                                control={
                                  <Checkbox
                                    sx={{ ...styles.checkBox, margin: '0 8px' }}
                                    checked={selectedDocs.length === total}
                                    onChange={() => fetchAllAsstIdsAndSelect()}
                                  />
                                }
                              />
                            </MenuItem>
                          </MenuList>
                        </ClickAwayListener>
                      </Paper>
                    </Grow>
                  )}
                </Popper>
              </TableCell>
              <TableCell key="assistantID" sx={{ ...styles.tableHeadCell, ...styles.tableHeadCellCommon }} align="left">
                {ready && t('INVOICE_LIST_TABLE_HEADER_ASST_ID')}
              </TableCell>
              <TableCell key="status" sx={{ ...styles.tableHeadCellStatusContainer, ...styles.tableHeadCellCommon }} align="left">
                {ready && t('INVOICE_LIST_TABLE_HEADER_STATUS')}
              </TableCell>
              {fields.map((field) => {
                if (!field.isActive) return null;
                if (field.key === 'status' || field.key === 'appUploadedDate') {
                  return null;
                }
                return (
                  <TableCell key={field.key} sx={{ ...styles.tableHeadCell, ...styles.tableHeadCellCommon }} align="left">
                    {ready && t(field.label)}
                    <IconButton sx={styles.dropDownIconContainer} onClick={() => handleSort(field.appDbField)}>
                      {sortDirection === 'asc' && sortField === field.assistantKey
                        ? <ArrowDropUpIcon sx={sortField === field.assistantKey ? styles.sortIconActive : styles.sortIcon} />
                        : <ArrowDropDownIcon sx={sortField === field.assistantKey ? styles.sortIconActive : styles.sortIcon} />
                      }
                    </IconButton>
                  </TableCell>
                );
              })}
              <TableCell key="appUploadedDate" sx={{ ...styles.tableHeadCellDateUploadedContainer, ...styles.tableHeadCellCommon }} align="left">
                {ready && t('INVOICE_EDIT_FORM_UPLOAD_DATE')}
                <IconButton sx={styles.dropDownIconContainer} onClick={() => handleSort('uploaded_date')}>
                  {sortDirection === 'asc' && sortField === 'uploaded_date'
                    ? <ArrowDropUpIcon sx={sortField === 'uploaded_date' ? styles.sortIconActive : styles.sortIcon} />
                    : <ArrowDropDownIcon sx={sortField === 'uploaded_date' ? styles.sortIconActive : styles.sortIcon} />
                  }
                </IconButton>
              </TableCell>
              <TableCell sx={{ ...styles.tableHeadCellLast, ...styles.tableHeadCellCommon }} align="left">
                {ready && t('DOCUMENT_LIST_TABLE_HEADER_ACTIONS')}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {docs.map((doc, i) => (
              <TableRow key={doc.id} sx={{ opacity: doc.outOfLimit ? 0.7 : 1 }}>
                <TableCell sx={{ ...styles.tableCellCheckBoxContainer, ...styles.tableBodyCellCommon }}>
                  <Checkbox
                    onChange={
                      (e) => handleSelectOneDoc(e, doc.assistantID || '', doc.status, doc.id, Boolean(doc.exportedToGstock), Boolean(doc.isDuplicate))
                    }
                    disabled={doc.outOfLimit}
                    checked={selectedDocs.some((selectedDoc) => selectedDoc.id === doc.id)}
                    sx={styles.checkBox}
                  />
                </TableCell>
                <TableCell
                  key={`assistantID_${doc.id}`}
                  sx={{ ...styles.tableBodyCell, ...styles.tableBodyCellCommon }}
                  align="left"
                  onClick={() => handleDocumentClick(doc, i)}
                >
                  {renderTableBodyCell('assistantID', 'text', doc.assistantID, doc)}
                </TableCell>
                <TableCell key={`status_${doc.id}`} sx={{ ...styles.tableBodyCellStatus, ...styles.tableBodyCellCommon }} align="left">
                  {renderTableBodyCell('status', 'float', doc.status, doc)}
                </TableCell>
                {doc.status === 200
                  ? renderSplitRow(doc)
                  : doc.status === 201
                    ? renderDuplicateRow(doc)
                    : (fields.map((field) => {
                        if (!field.isActive) return null;
                        let key = field.key as keyof DocType;
                        const { dataType } = field;
                        // @ts-ignore
                        if (key === 'documentDate') {
                          key = 'date';
                        }
                        const val = doc[key];
                        if (!(typeof val === 'string' || typeof val === 'number') && val !== undefined) {
                          return null;
                        }
                        if (key === 'status' || key === 'appUploadedDate') {
                          return null;
                        }
                        return (
                          <TableCell
                            key={`${field.key}_${doc.id}`} sx={{ ...styles.tableBodyCell, ...styles.tableBodyCellCommon }}
                            onClick={() => handleDocumentClick(doc, i)}
                            align="left"
                          >
                            {renderTableBodyCell(key, dataType, val, doc)}
                          </TableCell>
                        );
                      }))
                }
                <TableCell
                  key={`appUploadedDate_${doc.id}`}
                  onClick={() => handleDocumentClick(doc, i)}
                  sx={{ ...styles.tableBodyCellDateUploaded, ...styles.tableBodyCellCommon }}
                  align="left"
                >
                  {renderTableBodyCell('appUploadedDate', 'date', doc.appUploadedDate, doc,)}
                </TableCell>
                <TableCell sx={{ ...styles.tableBodyCellLast, ...styles.tableBodyCellCommon }} align="right">
                  {(doc.status !== 200 && doc.status !== 201 && doc.status !== 100) &&
                    <Tooltip title={t('INVOICE_EDIT_INVOICE_PREVIEW')}>
                      <IconButton disabled={doc.outOfLimit} onClick={()=>handleDocToPreview(doc,i)} sx={styles.previewIconBtn}>
                        <PreviewIcon />
                      </IconButton>
                    </Tooltip>
                  }
                  <IconButton
                    disabled={doc.outOfLimit || doc.status === 201 || (doc.status !== 200 && !IS_BRAND_PROCYS)}
                    onClick={(e) => handleOpenMenu(e, doc.id, doc.assistantID || '')}
                    sx={styles.moreIconBtn}
                  >
                    <MoreVertIcon sx={styles.moreIcon} />
                  </IconButton>
                  <Menu
                    id="invoice-actions"
                    anchorEl={menuAnchorEl}
                    keepMounted
                    open={Boolean(menuAnchorEl) && selectedDocID === doc.id}
                    onClose={handleCloseMenu}
                    sx={styles.menu}
                  >
                    <MenuItem
                      key={'log'}
                      onClick={(e) => onClickMenuItem(e, doc.status, doc.assistantID || '')}
                      sx={styles.menuItem}
                    >
                      {doc.status === 200
                        ? (<><KBLinkIcon sx={styles.kbIcon} />{ready && t('INVOICE_SPLIT_REQUEST_HELP')}</>)
                        : ready && t('INVOICE_EDIT_FORM_DOCUMENT_LOGS')
                      }
                    </MenuItem>
                  </Menu>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Grid>
      <TablePagination
        component="div"
        count={total}
        onPageChange={handlePageChange}
        onRowsPerPageChange={handleLimitChange}
        page={parseInt(page)}
        rowsPerPage={parseInt(limit)}
        rowsPerPageOptions={[10, 25, 50]}
        labelRowsPerPage={`${t('ROWS_PER_PAGE')}:`}
        sx={styles.tablePagination}
      />
      <DeleteDocumentModal
        open={openDeleteConf}
        id={selectedDocID}
        handleClose={() => setOpenDeleteConf(false)}
      />
      <ChooseValidatorModal
        open={chooseValidatorOpen}
        users={validatorUsers}
        selectedValidator={selectedValidator}
        assistantID={selectedAssistantID}
        handleClose={() => setChooseValidatorOpen(false)}
        setSelectedValidator={setSelectedValidator}
        handleStatusChange={handleSendToValidateStatusChange}
      />
      <SplitMenu
        splitMenuOpen={splitMenuOpen}
        handleClose={handleSplitMenuClose}
        invoice={documentToSplit}
        getInvoices={refreshDocuments}
      />
      <PreviewDocument
        open={showPreview}
        doc={docToPreview}
        handleClose={handleClosePreview}
        docs={docs.filter((e:any) => e.status !== 200 && e.status !== 201 && e.status !== 100)}
        index={previewIndex}
        page={page}
        limit={limit}
        total={total}
      />
      <DuplicateMenu
        duplicateMenuOpen={duplicateMenuOpen}
        handleClose={handleDuplicateMenuClose}
        doc={duplicateDoc}
        handleProcessDocument={handleProcessDocument}
      />
    </Grid>
  )
}

export default DocumentsTable
