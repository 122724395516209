import React from 'react';
import { useTranslation } from 'react-i18next';

import { Box, Grid, Tooltip, Typography } from '@mui/material';

import { useAuth } from 'src/hooks/useAuth';
import { statusFilters, getStatusIcon, invoiceStatusCode } from 'src/dashboard-new/utils';
import { invoiceStatusesKeys } from 'src/config';
import styles from './style';

interface PropTypes {
  selectedStatus: string;
  loading: boolean;
  handleOpenFilters: () => void;
  handleStatusFilterChange: (_s: string) => void;
  handleUpload: (_e: React.ChangeEvent<HTMLInputElement>) => void;
}

const DocumentsTableHeader = (props: PropTypes) => {
  const { ready, t } = useTranslation();
  const { user } = useAuth();

  const isToValidateStatusAllowed = user?.customisations.includes('toValidateStatus');

  const { selectedStatus, handleStatusFilterChange } = props;

  return (
    <Grid sx={styles.filtersRow}>
      <Box sx={styles.statusBtnsContainer} className="hide-scroll">
        {statusFilters.map((filter) => {
          if (isToValidateStatusAllowed && filter.id === invoiceStatusesKeys.reviewed) {
            return null;
          }
          if (!isToValidateStatusAllowed && filter.id === invoiceStatusesKeys.tovalidate) {
            return null;
          }
          return (
            <Tooltip key={filter.id} title={ready && t(filter.label)}>
              <Box
                key={filter.id}
                sx={{ ...styles.statusIconBtn, border: selectedStatus === filter.id ? '2px solid #3E8AFF99' : '2px solid #DDDEE799' }}
                onClick={() => handleStatusFilterChange(filter.id)}
              >
                {filter.id === invoiceStatusesKeys.all ? (
                  <Typography sx={{ ...styles.statusText, color: selectedStatus === filter.id ? '#3E8AFF' : '#4C4E6499' }}>
                    {ready && t(filter.label)}
                  </Typography>
                ): getStatusIcon(invoiceStatusCode[filter.id])}
              </Box>
            </Tooltip>
          );
        })}
      </Box>
    </Grid>
  )
}

export default DocumentsTableHeader
