import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Grid } from '@mui/material';

import { useAuth } from 'src/hooks/useAuth';
import { useConfig } from 'src/hooks/useConfig';
import DashboardOverview from 'src/dashboard-new/components/DashboardOverview/DashboardOverview';
import SideBarLayout from 'src/shared/layouts/SideBarLayout';
import TopBar from 'src/dashboard-new/components/Topbar/Topbar';
import DocumentsListContainer from 'src/documents-new/DocumentsListContainer';
import SelectDocument from 'src/documents/components/SelectDocument/SelectDocument';
import NewUIMessageModal from 'src/document-edit/components/NewUIMessageModal/NewUIMessageModal';
import styles from './style';

const DashboardNew = () => {
  const { t } = useTranslation();
  const { BRAND_NAME, IS_BRAND_PROCYS } = useConfig();
  const { user } = useAuth();

  const [showNewUIMsg, setShowNewUIMsg] = useState<boolean>(!user?.hasSeenNewUI);
  
  return (
    <>
      {
        !user?.documentType && <SelectDocument open />
      }
      <SideBarLayout title={`${t('DASHBOARD_ACTION_DASHBOARD')} | ${BRAND_NAME}`}>
        <Grid sx={styles.root}>
          <TopBar />
          <DashboardOverview />
          <DocumentsListContainer />
        </Grid>
        <NewUIMessageModal
          open={showNewUIMsg && IS_BRAND_PROCYS}
          handleClose={() => setShowNewUIMsg(false)}
        />
      </SideBarLayout> 
    </>
  )
}

export default DashboardNew