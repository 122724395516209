import { Box } from '@mui/material';

const VatESDetailsTopLineComponentNew = () => {
  return (
    <Box sx={styles.root}>
      <Box sx={styles.shape}></Box>
    </Box>
  )
}

const styles = {
  root:{
    display:'flex',
    flex:'1',
    width:'100%',
    borderTop:'2px solid rgba(216, 216, 221, .6)',
    justifyContent:'center',
    alignItems:'center',
    position:'relative',

  },
  shape:{
    width:'15px',
    height:'15px',
    display:'inline-block',
    borderRight:'2px solid rgba(216, 216, 221, .6)',
    borderBottom:'2px solid rgba(216, 216, 221, .6)',
    position:'absolute',
    transform:'rotate(-135deg)',
    backgroundColor:'#fff',
    zIndex:'1',
    marginRight:'-20px'

  }

}

export default VatESDetailsTopLineComponentNew