import theme from 'src/theme';

const styles = {
  root: {
    backgroundColor: '#f1f1f3',
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    maxHeight: '100vh',
    minHeight: '100%',
    width:'100dvw',
    maxWidth:'calc(100dvw - 65px)'
  },
  documentDataContainer: {
    backgroundColor: theme.palette.background.default,
    width: '400px',
    paddingTop: '12px',
    borderTopRightRadius: '8px',
    borderBottomRightRadius: '8px',
    border: '1px solid #ffffff',
    boxShadow: '0px 2px 10px 0px #4C4E6438',
    overflow: 'visible clip',
    position:'relative',
    resize:'horizontal',
    minWidth:'400px'
  },
  documentDisplayContainer: {
    flex: 1,
    display:'flex',
    justifyContent:'center',
    height: 'calc(95vh + 12px)',
    width:'calc(100dvw - 465px)',
    maxWidth:'calc(100dvw - 465px)',
    position:'relative',
    backgroundColor: '#f1f1f3',
  },
  backdrop: {
    zIndex: 1111111,
    color: '#ffffff',
  },
  resizeContainer:{
    position:'absolute',
    width:'6px',
    height:'30px',
    right:'-10px',
    backgroundColor:'#ccc',
    borderRadius:'4px',
    top:'calc(50dvh - 3px)',
    zIndex:1111,
    cursor:'ew-resize',
    "&:hover":{
      backgroundColor:'#3E8AFF',
    }
  }
}

export default styles;
