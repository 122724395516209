const styles = {
  action: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: '13px',
    fontWeight: '600',
    width: '108px',
    height: '32px',
  },
  statusChangeBtn: {
    padding: '4px 8px',
    height: '32px',
    minWidth: '132px',
    backgroundColor: '#FFFFFF',
    boxShadow: 'none',
    border: '1px solid #4C4E6438',
    borderRadius: '4px',
    textTransform: 'none' as const,
    justifyContent: 'center',
    '&:hover': {
      background: '#FFFFFF',
    },
    '& .MuiButton-startIcon': {
      marginLeft: '0px',
    }
  },
  statusChangeText: {
    color: '#4C4E64',
    fontSize: '14px',
    fontWeight: '500',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  dropDownIcon: {
    color: '#4C4E648A',
    size: '24px',
  },
  statusContainer: {
    display: 'flex',
    alignItems: 'center',
    padding: '8px 0px',
  },
  changeStatusLabel: {
    height: '24px',
    fontSize: '10px',
    fontWeight: '500',
    color: '#4C4E64',
    opacity: 0.5,
    textTransform: 'uppercase' as const,
    '&:hover': {
      backgroundColor: 'transparent',
      cursor: 'default',
    }
  },
  changeStatusOptions: {
    padding: '6px 10px',
    color: '#4C4E64',
    fontSize: '14px',
    fontWeight: '500',
    '&:hover': {
      background: '#C2CFE033',
    },
  },
  changeStatusIcon: {
    marginRight: '8px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
};

export default styles;
