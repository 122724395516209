import { useContext, useState } from 'react';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import Axios from 'axios';

import {
  Box, Button, Card, CardContent, FormHelperText,
  Grid, IconButton, InputAdornment, TextField, Typography
} from '@mui/material';
import { Visibility as VisibilityIcon, VisibilityOff as VisibilityOffIcon } from '@mui/icons-material';

import PasswordStrengthBar from 'src/account/components/PasswordStrengthComponent/PasswordStrengthComponent';
import authService from 'src/utils/authService';
import { appendContactSupport, axiosHeaders } from 'src/utils/helpers';
import { ERRORS } from 'src/config';
import ConfigContext from 'src/contexts/ConfigContext';
import styles from './style';

function Security() {
  const { enqueueSnackbar } = useSnackbar();
  const { t, ready } = useTranslation();

  const { LOGIN_PANEL_URL, API } = useContext(ConfigContext);

  const [showOldPass, setShowOldPass] = useState(false);
  const [showNewPass, setShowNewPass] = useState(false);
  const [showNewConfPass, setShowNewConfPass] = useState(false);

  return (
    <Formik
      initialValues={{
        currentPassword: '',
        password: '',
        passwordConfirm: ''
      }}
      validationSchema={Yup.object().shape({
        currentPassword: Yup.string()
          .required(t('ACCOUNT_SETTINGS_OLD_PASSWORD_REQUIRED')),
        password: Yup.string()
          .min(8, t('ACCOUNT_SETTINGS_PASSWORD_MIN'))
          .max(255)
          .matches(
            /^.*((?=.*[A-Z]){1}).*$/,
            'Add one uppercase character'
          )
          .matches(
            /^.*((?=.*[a-z]){1}).*$/,
            t('ACCOUNT_SETTINGS_PASSWORD_LOWER_CASE')
          )
          .matches(
            /^.*(?=.*\d).*$/,
            t('ACCOUNT_SETTINGS_PASSWORD_DIGIT')
          )
          .matches(
            /^.*((?=.*[!"#$%&'()*+,-./:;<=>?@[\\\]^_{|}~]){1}).*$/,
            t('ACCOUNT_SETTINGS_PASSWORD_SPECIAL_CHARACTER')
          )
          .required(t('ACCOUNT_SETTINGS_OLD_PASSWORD_REQUIRED')),
        passwordConfirm: Yup.string()
          .oneOf([Yup.ref('password'), null], t('ACCOUNT_SETTINGS_PASSWORD_MISMATCH'))
          .required(t('ACCOUNT_SETTINGS_OLD_PASSWORD_REQUIRED'))
      })}
      onSubmit={async (values, {
        resetForm,
        setErrors,
        setStatus,
        setSubmitting
      }) => {
        if (!authService.validateToken()) {
          enqueueSnackbar(t('PROCYS_LOGIN_SESSION_EXPIRED'), {
            variant: 'error',
          });
          setTimeout(() => {
            authService.logout(LOGIN_PANEL_URL);
          }, 2000);
          return;
        }

        try {
          // Make API request
          const data = {
            oldPassword: values.currentPassword,
            newPassword: values.password
          };
          const resp = await Axios.post(
            API.changePassword,
            data,
            axiosHeaders(localStorage.getItem('PROCYS_accessToken'))
          );

          if (resp.data.success) {
            resetForm();
            setStatus({ success: true });
            setSubmitting(false);
            enqueueSnackbar(t('ACCOUNT_SETTINGS_CHANGE_PASSWORD_SUCCESS'), {
              variant: 'success',
            });

            const userRef = authService.getUserRef();
            const userRole = authService.getUserRole();

            authService.setSession(resp.data.data.accessToken, userRole, userRef);
          } else {
            setStatus({ success: false });

            // Error message need to be changed when handling errors from ML-Assistant.
            setErrors({ submit: appendContactSupport(window.config.support_email, t('ACCOUNT_SETTINGS_CHANGE_PASSWORD_FAILURE'), t) });
            setSubmitting(false);
          }
        } catch (error) {
          setStatus({ success: false });
          setErrors({ submit: appendContactSupport(window.config.support_email, ERRORS[error.response.data.i18n] || error.response.data.message, t) });
          setSubmitting(false);
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values
      }) => (
        <form onSubmit={handleSubmit}>
          <Grid container>
            <Grid item xs={12}>
              <Card style={styles.root}>
                <Typography sx={styles.header}>
                  {ready && t('ACCOUNT_SETTINGS_MY_PROFILE_CHANGE_PASSWORD')}
                </Typography>
                <CardContent style={styles.formContainer}>
                  <Grid container spacing={3} style={styles.container}>
                    <Grid item xs={12} sx={styles.formInput}>
                      <TextField
                        error={Boolean(touched.currentPassword && errors.currentPassword)}
                        fullWidth
                        helperText={touched.currentPassword && errors.currentPassword}
                        label={ready && t('ACCOUNT_SETTINGS_MY_PROFILE_OLD_PASSWORD')}
                        name="currentPassword"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        type={showOldPass ? 'text' : 'password'}
                        value={values.currentPassword}
                        variant="outlined"
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                style={styles.iconWrapper}
                                onClick={() => setShowOldPass(!showOldPass)}
                              >
                                {
                                  showOldPass
                                    ? <VisibilityOffIcon style={styles.visibleIcon} />
                                    : <VisibilityIcon style={styles.visibleIcon} />
                                }
                              </IconButton>
                            </InputAdornment>
                          ),
                          style: styles.inputProps
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} sx={styles.formInput}>
                      <TextField
                        error={Boolean(touched.password && errors.password)}
                        fullWidth
                        label={ready && t('ACCOUNT_SETTINGS_MY_PROFILE_NEW_PASSWORD')}
                        name="password"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        type={showNewPass ? 'text' : 'password'}
                        value={values.password}
                        variant="outlined"
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                style={styles.iconWrapper}
                                onClick={() => setShowNewPass(!showNewPass)}
                              >
                                {
                                  showNewPass
                                    ? <VisibilityOffIcon style={styles.visibleIcon} />
                                    : <VisibilityIcon style={styles.visibleIcon} />
                                }
                              </IconButton>
                            </InputAdornment>
                          ),
                          style: styles.inputProps
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} sx={styles.formInputLast}>
                      <TextField
                        error={Boolean(touched.passwordConfirm && errors.passwordConfirm)}
                        fullWidth
                        label={ready && t('ACCOUNT_SETTINGS_MY_PROFILE_CONFIRM_NEW_PASSWORD')}
                        name="passwordConfirm"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        type={showNewConfPass ? 'text' : 'password'}
                        value={values.passwordConfirm}
                        variant="outlined"
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                style={styles.iconWrapper}
                                onClick={() => setShowNewConfPass(!showNewConfPass)}
                              >
                                {
                                  showNewConfPass
                                    ? <VisibilityOffIcon style={styles.visibleIcon} />
                                    : <VisibilityIcon style={styles.visibleIcon} />
                                }
                              </IconButton>
                            </InputAdornment>
                          ),
                          style: styles.inputProps
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} sx={styles.strengthBarWrapper}>
                      <PasswordStrengthBar
                        password={values.password}
                        passwordHelper={
                          touched.password && errors.password ? errors.password // eslint-disable-line
                            : touched.passwordConfirm && errors.passwordConfirm ? errors.passwordConfirm : ''
                        }
                      />
                    </Grid>
                  </Grid>
                  {errors.submit && (
                    <Box mt={3}>
                      <FormHelperText error>
                        {errors.submit}
                      </FormHelperText>
                    </Box>
                  )}
                </CardContent>
                <Box
                  p={2}
                  display="flex"
                  justifyContent="flex-end"
                  style={styles.saveBtnContainer}
                >
                  <Button
                    disabled={isSubmitting}
                    type="submit"
                    variant="contained"
                    style={styles.saveBtn}
                  >
                    {ready && t('ACCOUNT_SETTINGS_MY_PROFILE_SUBMIT_CHANGE_PASSWORD')}
                  </Button>
                </Box>
              </Card>
            </Grid>
          </Grid>
        </form>
      )}
    </Formik>
  );
}

export default Security;
