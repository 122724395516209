const styles = {
  root: {
    maxHeight: '256px',
    minHeight: '20vh',
    margin: '0px 8px',
    backgroundColor: '#FFFFFF',
    borderTopLeftRadius: '8px',
    borderTopRightRadius: '8px',
    borderTop: '1px solid #4C4E641F',
    boxShadow: '0px -2px 10px 0px #4C4E6438',
    position: 'absolute',
    bottom: '-18px',
    right:'5px',
    width:'calc(100% - 20px)',
    flex:'1',
    overflow: 'hidden'
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '8px 8px',
    height: '52px',
  },
  titleContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  headerTitle: {
    fontSize: '16px',
    color: '#4C4E64',
    fontWeight: '600',
    lineHeight: '32px',
    letterSpacing: '-0.25px',
    marginRight: '16px'
  },
  iconBtnAddLine: {
    border: '1px solid #4C4E6438',
    borderRight: 'none',
    height: '32px',
    padding: '4px 8px 4px 8px',
    borderRadius: '4px 0px 0px 4px'
  },
  iconBtnAddLineOnly: {
    border: '1px solid #4C4E6438',
    height: '32px',
    padding: '4px 8px 4px 8px',
    borderRadius: '4px'
  },
  iconBtnSettings: {
    border: '1px solid #4C4E6438',
    height: '32px',
    padding: '4px 8px 4px 8px',
    borderRadius: '0px 4px 4px 0px',
  },
  undoBtn: {
    padding: '4px 0px 4px 8px',
  },
  deleteIconBtn: {
    padding: '0px'
  },
  icon: {
    color: '#6D788D',
    height: '20px',
    width: '20px',
  },
  tablesContainer: {
    display: 'flex',
    maxHeight: '202px',
    overflowY: 'scroll' as const,
  },
  table1: {
    width: '14%',
    boxShadow: '-4px 0px 8px 4px #4C4E646B',
    zIndex: 10,
    overflowY: 'scroll' as const,
    borderRadius: 0,
    paddingBottom: '6px'
  },
  table2: {
    width: '86%',
    overflowX: 'scroll' as const,
    overflowY: 'scroll' as const,
    borderRadius: 0,
  },
  draggableIconHeader: {
    width: '32px',
    height: '38px',
    padding: '2px',
    backgroundColor: '#F5F5F7',
    fontSize: '12px',
    fontWeight: 500,
    lineHeight: '24px',
    letterSpacing: '-0.25px',
    color: '#4C4E64DE'
  },
  columnNumber: {
    width: '32px',
    height: '38px',
    padding: '2px',
    paddingRight: '2px',
    backgroundColor: '#F5F5F7',
    fontSize: '12px',
    fontWeight: 500,
    lineHeight: '24px',
    letterSpacing: '-0.25px',
    color: '#4C4E64DE'
  },
  headerCell: {
    height: '38px !important',
    padding: '2px 16px !important',
    backgroundColor: '#F5F5F7',
    fontSize: '12px',
    fontWeight: 500,
    lineHeight: '24px',
    letterSpacing: '-0.25px',
    color: '#4C4E64DE',
    whiteSpace: 'nowrap',
  },
  bodyCell: {
    height: '32px',
    fontSize: '12px',
    whiteSpace: 'nowrap',
  },
  deleteLineHeader: {
    width: '16px !important',
    height: '38px !important',
    backgroundColor: '#F5F5F7',
    fontSize: '12px',
    fontWeight: 500,
    lineHeight: '24px',
    letterSpacing: '-0.25px',
    color: '#4C4E64DE'
  },
  lineNoContainer: {
    width: '32px',
    height: '32px',
    padding: '2px',
    textAlign: 'center' as const,
    fontSize: '13px',
    lineHeight: '32px',
    color: '#4C4E64',
  },
  draggableIcon: {
    width: '32px',
    height: '32px',
    padding: '2px',
    textAlign: 'center' as const,
  },
  inputRoot: {
    height: '32px',
  },
  input: {
    textAlign: 'center' as const,
    fontSize: '13px',
    lineHeight: '32px !important',
    color: '#4C4E64 !important',
    padding: '4px 8px !important',
  },
  checkBox: {
    color: 'rgba(0, 0, 0, 0.26)',
    fontWeight: 500
  },
  reprocessColumnHeader: {
    cursor: 'pointer',
  },
  selectedColumnHeader: {
    backgroundColor: '#3E8AFF4D',
  },
  reprocessingBtn: {
    textTransform: 'none',
    border: '1px solid #4C4E6438',
    marginLeft: '8px',
    height: '32px',
    padding: '4px 12px',
    borderRadius: '4px',
    fontSize: '12px',
    color: '#4C4E64',
    fontWeight: 500,
    '&:hover': {
      boxShadow: '0px 1px 2px #4C4E646B',
      border: '1px #e4e4e4 solid',
      backgroundColor: '#FFFFFF',
    },
    '&:disabled': {
      border: '1px #e4e4e4 solid',
    }
  }
};

export default styles;
