import { useState, useEffect } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { Backdrop, CircularProgress } from '@mui/material';

import SideBarLayout from 'src/shared/layouts/SideBarLayout';
import { RouteParams } from 'src/document-edit/documentTypes';
import { useConfig } from 'src/hooks/useConfig';
import { RootState } from 'src/types';
import { useAuth } from 'src/hooks/useAuth';
import { userRoles } from 'src/config';
import { fetchHeaderFields, fetchLineFields } from 'src/shared/fieldsActions';
import { UnknownAction } from 'redux';
import DocumentEdit from 'src/document-edit/DocumentEdit';
import LoadingScreen from 'src/shared/screens/LoadingScreen';

const DocumentEditContainer = () => {
  const match = useRouteMatch<RouteParams>();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { BRAND_NAME } = useConfig();
  const { user } = useAuth();

  const fieldsLoading: boolean = useSelector((state: RootState) => state.fields.fieldsLoading);
  const headerEntity: string = useSelector((state: RootState) => state.fields.headerEntity);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    const assistantID = match && match.params ? match.params.id : '0';
    if (user?.userRole === userRoles.annotator) {
      dispatch(fetchHeaderFields(user?.companyID || '', headerEntity, assistantID) as unknown as UnknownAction);
      dispatch(fetchLineFields(user?.companyID || '', assistantID) as unknown as UnknownAction);
    }
  }, []);

  if (fieldsLoading) {
    return <LoadingScreen />;
  }

  return (
    <SideBarLayout title={`${t('INVOICE_EDIT_PAGE_TITLE')} | ${BRAND_NAME}`}>
      <DocumentEdit
        assistantID={match && match.params ? match.params.id : '0'}
        setLoading={setLoading}
      />
      <Backdrop style={styles.backdrop} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </SideBarLayout>
  );
}

export default DocumentEditContainer;

const styles = {
  backdrop: {
    zIndex: 1111111,
    color: '#ffffff',
  },
}
