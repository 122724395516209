import React,{ useEffect, useState, useContext, useRef, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import { countries } from 'countries-list';
import { useDispatch, useSelector } from 'react-redux';
import { UnknownAction } from 'redux';
import Axios from 'axios';

import { Grid, Box } from '@mui/material';

import ConfigContext from 'src/contexts/ConfigContext';
import authService from 'src/utils/authService';
import { useAuth } from 'src/hooks/useAuth';
import useHeight from 'src/hooks/useHeight';
import {
  addDays, appendContactSupport, axiosHeaders, calculateBaseAmount, calculateDiscountRate,
  calculateLineAmountExtraCost1, calculateLineAmountExtraCost2, calculateLineAmountExtraCost3,
  calculateLineAmountExtraCost4, calculateSubtotal, calculateVatAmount, getLocalisedErrorString, sendFeedback
} from 'src/utils/helpers';
import {
  lineColumnsReprocess, makroSuppliers, secondaryDocumentTypes, supplierData, userRoles
} from 'src/config';
import {
  CustomError, DocumentEsLineType, DocumentLineType, DocumentVatType, FieldType, DocType, ProjectType, RootState,
  SupplierInventoryType, SupplierType, UserDataType,
} from 'src/types';
import DocumentFields from 'src/document-edit/components/DocumentFields/DocumentFields';
import ManageFieldsNew from 'src/shared/components/ManageFieldsNew/ManageFieldsNew';
import LinesComponent from 'src/document-edit/components/LinesComponent/LinesComponent';
import InfoModal from 'src/document-edit/components/InfoModal/InfoModal';
import DeleteDocumentModal from 'src/document-edit/components/DeleteDocumentModal/DeleteDocumentModal';
import ChooseValidatorModal from 'src/document-edit/components/ChooseValidatorModal/ChooseValidatorModal';
import PdfViewer from 'src/document-edit/components/PdfViewer/PdfViewer';
import { StatusChangeRequest, DocumentEditFormVatEsLineType } from './documentTypes';
import {
  annotateTimeVatEsMap, calculateVAT, esLineLabels, formatFieldTrustScores, formatLinesReprocessingData,
  getDescriptionFromTFSupplier, getDocumentEditFormValues, getIsError, initializeAnnotateFieldTimes,
  getDocumentEditFormCandidates, getDocumentEditFormXMLValues, getFieldTrustScores, lineField, newLineDetails,
  receiverRelatedFields, senderRelatedFields, vatLineLabels, vatLineFieldMapping, esLineFieldMapping, documentTypes
} from './utils';
import { fetchGlas } from 'src/dimensions/glas/actions';
import { fetchVatCodes } from 'src/dimensions/vatcodes/actions';
import { fetchCostCenters } from 'src/dimensions/costcenters/actions';
import { fetchHeaderFields, fetchLineFields } from 'src/shared/fieldsActions';
import { IHighlight } from 'src/pdf-highligher';
import useWidth from 'src/hooks/useWidth';
import styles from './style';

type PropsType ={
  assistantID:string;
  handleClose?:Function;
  setLoading:(_loading: boolean) => void;
  moveNext?:Function;
  movePrevious?:Function;
  disableMove?:boolean[];
  setLoadingPdf?:Function;
}

const DocumentEdit = ( props: PropsType) => {
  const{
    assistantID,
    handleClose,
    setLoading,
    moveNext,
    movePrevious,
    disableMove,
  } = props;

  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const dispatch = useDispatch();

  const { API, LOGIN_PANEL_URL } = useContext(ConfigContext);
  const { user } = useAuth();

  const leadingWhiteSpace = /^\s+.+$/;

  const isPassportOrID = user?.documentType === 'id';
  const isAutoNavigationAllowed = user?.customisations.includes('autoNavigation') || false;
  const decimalSeparator = user?.monetaryDecimalSeparator || '.';
  const dateFieldRule = user?.fieldRules?.find((fr) => fr.field === 'documentDate');
  const isTwinfield = user?.integrations.includes('tf') || false;
  const custom2FieldInDocumentForm = user?.customisations.includes('custom2FieldInDocumentForm') || false;
  const amountRegex = new RegExp(`^-?\\d*[${decimalSeparator === '.' ? '.' : `.${decimalSeparator}`}]?\\d+$`);

  const resizeRef = useRef<HTMLDivElement | null>(null)
  
  const headerFields: FieldType[] = useSelector((state: RootState) => state.fields.headerFields);
  const lineFields: FieldType[] = useSelector((state: RootState) => state.fields.lineFields);
  const headerEntity: string = useSelector((state: RootState) => state.fields.headerEntity);

  const [parameterDocCompany, setParameterDocCompany] = useState(user?.companyID || '');
  const [parameterCompany] = useState(user?.companyID || '');
  const [manageFieldsEntity, setManageFieldsEntity] = useState(headerEntity || 'invoice');

  const [doc, setDoc] = useState<DocType>({ id: 0, status: 0, lines: [] });
  const [xml, setXml] = useState<string>('');
  const [details, _setDetails] = useState<Record<string, string>>({
    ...headerFields.reduce((acc, item) => {
      acc[item.key] = '';
      return acc;
    }, {} as Record<string, string>),
    senderCountryCode: '',
    receiverCountryCode: '',
    documentType: documentTypes.invoice,
    secondaryDocumentType: secondaryDocumentTypes.invoice
  });
  const detailsRef = useRef(details);
  const setDetails = (val: Record<string, string>) => {
    detailsRef.current = val;
    _setDetails(val);
  };
  const [detailsXML, setDetailsXML] = useState<Record<string, string[]>>({
    ...headerFields.reduce((acc, item) => {
      if (item.assistantKey) {
        acc[item.assistantKey] = [];
      } else {
        acc[item.key] = [];
      }
      return acc;
    }, {} as Record<string, string[]>),
    receiver_country_code: [],
    sender_country_code: []
  });
  const [detailsCandidates, setDetailsCandidates] = useState<Record<string, string[]>>(detailsXML);
  const [supplier, setSupplier] = useState<SupplierType | null>(null);
  const [lineDetails, _setLineDetails] = useState<DocumentLineType[]>(doc.lines || []);
  const lineDetailsRef = useRef(lineDetails);
  const setLineDetails = (val: DocumentLineType[]) => {
    lineDetailsRef.current = val;
    _setLineDetails(val);
  };
  const [vatLines, _setVatLines] = useState<DocumentEditFormVatEsLineType[]>([]);
  const vatLinesRef = useRef(vatLines);
  const setVatLines = (val: DocumentEditFormVatEsLineType[]) => {
    vatLinesRef.current = val;
    _setVatLines(val);
  };
  const [esLines, _setEsLines] = useState<DocumentEditFormVatEsLineType[]>([]);
  const esLinesRef = useRef(esLines);
  const setEsLines = (val: DocumentEditFormVatEsLineType[]) => {
    esLinesRef.current = val;
    _setEsLines(val);
  };
  const [doNotPay, _setDoNotPay] = useState<boolean>(Boolean(doc.doNotPay));
  const [tags, setTags] = useState<string[]>(doc.documentTags?.split(',') || []);

  const [previousInvoice, setPreviousInvoice] = useState<DocType | null>(null);
  const [nextInvoice, setNextInvoice] = useState<DocType | null>(null);
  const [selectedValidator, setSelectedValidator] = useState<UserDataType | null>(null);
  const [newlyAddedUser, setNewlyAddedUser] = useState<UserDataType | null>(null);
  const [supplierFields, setSupplierFields] = useState<FieldType[]>([]);
  const [qbTerms, setQBTerms] = useState<{ code: string, name: string }[]>([]);
  const [suppliers, setSuppliers] =
    useState<SupplierType[]>(JSON.parse(window.sessionStorage.getItem('suppliers') || '[]'));
  const [projects, setProjects] = useState<ProjectType[]>([]);
  const [users, setUsers] = useState<UserDataType[]>([]);
  const [renderedFields, _setRenderedFields] = useState<FieldType[]>([]);
  const renderedFieldsRef = useRef(renderedFields);
  const setRenderedFields = (val: FieldType[]) => {
    renderedFieldsRef.current = val;
    _setRenderedFields(val);
  };

  const [fieldTrustScores, _setFieldTrustScores] = useState<Record<string, number>>(headerFields.reduce((acc, item) => {
    acc[item.key] = -1;
    return acc;
  }, {} as Record<string, number>));
  const fieldTrustScoresRef = useRef(fieldTrustScores);
  const setFieldTrustScores = (val: Record<string, number>) => {
    fieldTrustScoresRef.current = val;
    _setFieldTrustScores(val);
  };
  const [annotateFieldsTime, _setAnnotateFieldsTime] = useState<Record<string, number>>({});
  const annotateFieldsTimeRef = useRef(annotateFieldsTime);
  const setAnnotateFieldsTime = (val: Record<string, number>) => {
    annotateFieldsTimeRef.current = val;
    _setAnnotateFieldsTime(val);
  };
  const [invoiceAnnotateStart, setInvoiceAnnotateStart] = useState<number | null>(null);
  const [invoiceAnnotateTime, setInvoiceAnnotateTime] = useState(0);
  const [annotateSelectedField, setAnnotateSelectedField] = useState<string | null>(null);
  const [annotateFieldStart, setAnnotateFieldStart] = useState<number | null>(null);

  const [submitting, setSubmitting] = useState<boolean>(false);
  const [statusChangeLoading, setStatusChangeLoading] = useState<boolean>(false);
  const [showManageFields, setShowManageFields] = useState(false);
  const [viewLines, setViewLines] = useState<boolean>(false);
  const [showInfoModal, setShowInfoModal] = useState<boolean>(false);
  const [openDeleteConf, setOpenDeleteConf] = useState<boolean>(false);
  const [chooseValidatorOpen, setChooseValidatorOpen] = useState<boolean>(false);
  const [isSubmitClicked, _setIsSubmitClicked] = useState<boolean>(true);
  const isSubmitClickedRef = useRef(isSubmitClicked);
  const setIsSubmitClicked = (val: boolean) => {
    isSubmitClickedRef.current = val;
    _setIsSubmitClicked(val);
  };

  const [highlights, _setHighlights] = useState<Array<IHighlight>>([]);
  const highlightsRef = useRef(highlights);
  const setHighlights = (val: Array<IHighlight>) => {
    highlightsRef.current = val;
    _setHighlights(val);
  };
  const [showDetails, setShowDetails] = useState<Record<string, boolean>>({
    sender: true,
    receiver: true,
    VAT: false,
    ES: false,
  });
  const [selectedTextField, _setSelectedTextField] = useState<FieldType>({
    key: '',
    assistantKey: '',
    label: '',
    appDbField: '',
    dataType: '',
    exportMapKey: '',
    position: -1,
  });
  const selectedTextFieldRef = useRef(selectedTextField);
  const setSelectedTextField = (val: FieldType) => {
    selectedTextFieldRef.current = val;
    _setSelectedTextField(val);
  };

  const [reprocessingLines, setReprocessingLines] = useState(false);
  const [linesDataForReprocessing, setLinesDataForReprocessing] = useState<Record<string, IHighlight[]>>({
    linesTotal: [],
    lineAll: [],
    lineArticle: [],
    lineQuantity: [],
    lineAmount: [],
    lineName: []
  });

  const statusChangedRef: React.MutableRefObject<boolean | null> = useRef<boolean>(null);
  const linesRef = useRef<HTMLDivElement>(null);
  const linesComponentHeight = useHeight(linesRef, viewLines, lineDetailsRef.current);
  const [pdfViewerHeight, setPdfViewerHeight] = useState(`calc(100vh - 84px - ${Math.round(linesComponentHeight)}px)`);
  const fieldsContainerRef = useRef<HTMLDivElement>(null);
  const fieldsContainerWidth = useWidth(fieldsContainerRef, 0);
  const pdfViewerWidth = `calc(100vw - 65px - ${fieldsContainerWidth}px - 4px)`;
  const pdfViewerWidthForLines = `calc(100vw - 65px - ${fieldsContainerWidth}px - 4px - 16px)`;
  const [isMouseDown, _setIsMouseDown] = useState<boolean>(false);
  const isMouseDownRef = useRef(isMouseDown);
  const setIsMouseDown = (val: boolean) => {
    isMouseDownRef.current = val;
    _setIsMouseDown(val);
  };

  const moveToNextRef = useRef<number | null>(null);
  const setMoveToNext = (val: number | null) => {
    moveToNextRef.current = val;
  };
  const tipOriginalValue = useRef<string>('');

  const setTipOriginalValue = (val: string) => {
    tipOriginalValue.current = val;
  };

  useEffect(() => {
    setPdfViewerHeight(`calc(100vh - 84px - ${Math.round(linesComponentHeight)}px)`);
  }, [linesComponentHeight]);

  const setDoNotPay = (value: boolean) => {
    setIsSubmitClicked(false);
    _setDoNotPay(value);
  };

  const updateSelectedValidator = (newValidator: UserDataType) => {
    setSelectedValidator(newValidator);
    setNewlyAddedUser(null);
  };

  const handleChooseValidatorModalOpen = () => {
    setChooseValidatorOpen(!chooseValidatorOpen);
    pauseInvoiceAnnotateTime();
  };

  const handleChooseValidatorModalClose = () => {
    setChooseValidatorOpen(false);
    restartInvoiceAnnotateTime();
  };

  const getDocument = async (doNotFetchSupplier?: boolean) => {
    const id = assistantID;
    try {
      const resp = await Axios.get(
        `${API.getInvoiceByID}${id}`,
        axiosHeaders(localStorage.getItem('PROCYS_accessToken'))
      );
      if (resp.data.success) {
        setParameterDocCompany(resp.data.data.company);
        if (!doNotFetchSupplier) {
          findAllSuppliers(resp.data.data.company);
        }

        setDoc(resp.data.data);
        setLoading(false);

        if (resp.data.data.status === 500) {
          sendFeedback(API.feedbackLogs, 'work with invoice', user?.email, user?.companyID);
        }
      } else {
        history.push('/documents');
      }
    } catch (e) {
      const error = e as CustomError;
      if (error?.response?.data?.i18n) {
        enqueueSnackbar(getLocalisedErrorString(error.response.data.i18n, t), {
          variant: 'error',
          autoHideDuration: 5000
        });
      }
      history.push('/documents');
    }
  };

  const getSupplierFields = async () => {
    try {
      const response = await Axios.get(
        `${API.fields}/supplier/${encodeURIComponent(parameterCompany)}`,
        axiosHeaders(localStorage.getItem('PROCYS_accessToken'))
      );
      if (response.data.success) {
        if (response.data.data) {
          setSupplierFields(response.data.data);
        } else {
          setSupplierFields([]);
        }
      }
    } catch (error) {
      setSupplierFields([]);
    }
  };

  const getOtherInvoice = async (invoicePage: number) => {
    const queryVal = new URLSearchParams(history.location.search);
    const thisSearch = queryVal.get('query');
    const thisFilter = queryVal.get('status');
    const thisTime = queryVal.get('time');
    const supplierFilter = JSON.parse(queryVal.get('supplier_filter') || 'false');
    const suppliersList = JSON.parse(window.sessionStorage.getItem('selectedSuppliers') || '[]');
    const ownerFilter = JSON.parse(queryVal.get('owner_filter') || 'false');
    const ownersList = JSON.parse(window.sessionStorage.getItem('selectedOwners') || '[]');
    const companyFilter = JSON.parse(queryVal.get('company_filter') || 'false');
    const companiesList = JSON.parse(window.sessionStorage.getItem('selectedCompanies') || '[]');
    const thisStartTime = queryVal.get('start_time');
    const thisEndTime = queryVal.get('end_time');
    const failedToExport = JSON.parse(queryVal.get('failed_to_export') || 'false');
    const sortBy = queryVal.get('sort_by');
    const order = queryVal.get('order');
    let thisSuppliers = '';
    if (supplierFilter && Array.isArray(suppliersList)) {
      thisSuppliers = suppliersList.map((supplier) => supplier.creditorCode).join(',');
    } else if (supplierFilter && suppliersList) {
      thisSuppliers = suppliersList;
    }
    let thisOwners = '';
    if (ownerFilter && Array.isArray(ownersList)) {
      thisOwners = ownersList.map((owner) => owner.email).join(',');
    } else if (ownerFilter && ownersList) {
      thisOwners = ownersList;
    }
    let thisCompanies = [];
    if (companyFilter && companiesList.length > 0 && companiesList[0].companyId) {
      thisCompanies = companiesList.map((company: {name: string, companyId: string}) => company.companyId);
    } else if (companyFilter && companiesList.length > 0) {
      thisCompanies = companiesList;
    }
    try {
      let url = `${API.getAllInvoices}/${encodeURIComponent(user?.companyID || '')}?page=${invoicePage}&limit=1${thisSearch !== null
        ? `&search=${thisSearch}` : ''}&order=${sortBy
        ? `${order}&sortby=${sortBy}` : 'desc'}${thisFilter !== null
        ? `&filter=${thisFilter}` : ''}${failedToExport
        ? '&failedToExport=true' : ''}`;
      if (thisStartTime !== null && thisEndTime !== null) {
        url = `${url}&startTime=${thisStartTime}&endTime=${thisEndTime}`;
      } else {
        url = `${url}${thisTime !== null ? `&time=${thisTime}` : ''}`;
      }
      const body = { suppliers: thisSuppliers, owners: thisOwners, companies: thisCompanies };
      const response = await Axios.post(url, body, axiosHeaders(localStorage.getItem('PROCYS_accessToken')));
      if (response.data.success && response.data.data.length > 0) {
        return response.data.data[0];
      }
      return null;
    } catch (error) {
      return null;
    }
  };

  const fetchPrevAndNext = async () => {
    const queryVal = new URLSearchParams(history.location.search);
    const invoicePage = queryVal.get('document_page');
    const total = parseInt(queryVal.get('total') || '1', 10);
    if (invoicePage) {
      const currentInvoice = parseInt(invoicePage, 10);
      if (currentInvoice > 1) {
        const previousInvoice = await getOtherInvoice(currentInvoice - 1);
        setPreviousInvoice(previousInvoice);
      }
      if (currentInvoice < total) {
        const nextInvoice = await getOtherInvoice(currentInvoice + 1);
        setNextInvoice(nextInvoice);
      }
    }
  };

  const getXML = async () => {
    const id = assistantID;
    try {
      const xmlPage = await Axios.get(
        `${API.getXmlInvoice}${id}`,
        axiosHeaders(localStorage.getItem('PROCYS_accessToken'))
      );
      if (xmlPage.data.success && xmlPage.data.data) {
        setXml(xmlPage.data.data.xml);
        if (xmlPage.data.data.candidates) {
          const newDetailsXml = getDocumentEditFormXMLValues(headerFields, xmlPage.data.data.xml);
          setDetailsXML(newDetailsXml);
          setDetailsCandidates(getDocumentEditFormCandidates(headerFields, newDetailsXml, detailsRef.current));
        }
      } else {
        setXml('');
      }
    } catch (error) {
      setXml('');
    }
  };

  const findAllSuppliers = async (companyID?: string) => {
    if (window.sessionStorage.getItem('suppliers') === null) {
      setLoading(true);
    }
    try {
      const resp = await Axios.get(
        `${API.findSuppliers}/${companyID || parameterDocCompany}`,
        axiosHeaders(localStorage.getItem('PROCYS_accessToken'))
      );
      if (resp.data.success) {
        window.sessionStorage.setItem('suppliers', JSON.stringify(resp.data.data));
        setSuppliers(resp.data.data);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const getQBTerms = async () => {
    try {
      const response = await Axios.get(
        `${API.qbTerms}/${encodeURIComponent(parameterCompany)}`,
        axiosHeaders(localStorage.getItem('PROCYS_accessToken'))
      );
      if (response.data.success) {
        if (response.data.data) {
          setQBTerms(response.data.data);
        } else {
          setQBTerms([]);
        }
      }
    } catch (error) {
      setQBTerms([]);
    }
  };

  const getAllGLAccounts = () => {
    dispatch(fetchGlas(parameterDocCompany) as unknown as UnknownAction);
  };

  const getAllVatCodes = () => {
    dispatch(fetchVatCodes(parameterDocCompany) as unknown as UnknownAction);
  };

  const getAllCostCenters = async () => {
    dispatch(fetchCostCenters(parameterDocCompany) as unknown as UnknownAction);
  };

  const getAllProjects = async () => {
    try {
      const response = await Axios.get(
        `${API.projectsByCompany}/${encodeURIComponent(parameterDocCompany)}`,
        axiosHeaders(localStorage.getItem('PROCYS_accessToken'))
      );
      if (response.data.success) {
        if (response.data.data.length > 0) {
          setProjects([...supplierData.defaultProjects, ...response.data.data]);
        }
      }
    } catch (error) {
      //
    }
  };

  const getUsers = async () => {
    try {
      const url = `${API.getUsersByCompanies}${encodeURIComponent(user?.companyID || '')}?permission=invoice_validate`;
      const resp = await Axios.get(url, axiosHeaders(localStorage.getItem('PROCYS_accessToken')));
      if (resp.data.success) {
        setUsers(resp.data.data);
        const newUser = resp.data.data?.find((u: UserDataType) => u.email === newlyAddedUser?.email);
        const invoiceOwner = resp.data.data?.find((u: UserDataType) => u.email === doc?.owner);
        if (newlyAddedUser && newUser) {
          updateSelectedValidator(newUser);
        } else if (invoiceOwner) {
          updateSelectedValidator(invoiceOwner);
        } else {
          updateSelectedValidator(resp.data.data[0]);
        }
      }
    } catch (error) {
      //
    }
  };

  const loadData = async () => {
    getXML();
    await getDocument();
    fetchPrevAndNext();
    getSupplierFields();
    if (headerEntity !== documentTypes.passportOrId) {
      getQBTerms();
      getAllGLAccounts();
      getAllVatCodes();
      getAllCostCenters();
      getAllProjects();
    }
    await getUsers();
    setAnnotateFieldsTime(initializeAnnotateFieldTimes([...headerFields, ...lineFields].filter((f) => f.isActive || f.isMandatory)));
    setInvoiceAnnotateStart(new Date().getTime());
  };

  const getSupplierByCode = async (id: string) => {
    try {
      const response = await Axios.get(
        `${API.suppliersV2}/${id}/${parameterDocCompany}`,
        axiosHeaders(localStorage.getItem('PROCYS_accessToken'))
      );
      if (response.data.success) {
        setSupplier(response.data.data);
        return response.data.data;
      }
      return null;
    } catch (error) {
      return null;
    }
  };

  const updateAnnotateFieldsTime = () => {
    if (annotateSelectedField && annotateFieldStart) {
      if (annotateFieldsTime[annotateSelectedField] !== undefined) {
        setAnnotateFieldsTime({
          ...annotateFieldsTime,
          [annotateSelectedField]:
              annotateFieldsTime[annotateSelectedField] + (new Date().getTime() - annotateFieldStart)
        });
      }
    }
  };

  const pauseInvoiceAnnotateTime = () => {
    if (invoiceAnnotateStart) {
      const timeSpent = invoiceAnnotateTime + (new Date().getTime() - invoiceAnnotateStart);
      setInvoiceAnnotateTime(timeSpent);
      setInvoiceAnnotateStart(null);
    }
    updateAnnotateFieldsTime();
    setAnnotateSelectedField(null);
    setAnnotateFieldStart(null);
  };

  const restartInvoiceAnnotateTime = () => {
    setInvoiceAnnotateStart(new Date().getTime());
  };

  const revertTipText = (key: string, dataType: string, value: string) => {
    let val = value;
    if (!value) {
      return;
    }
    const isErrorVal = getIsError(key, dataType, value, user);
    if (isErrorVal) {
      val = '';
    }
    if (key.startsWith('VATLine')) {
      const vatLineSplit = key.split('_');
      const vatLineKey = vatLineSplit[0].replace('Line', '');
      const vatLineIndex = parseInt(vatLineSplit[1]) - 1;
      const vatLineProp = vatLineSplit[2] as keyof DocumentEditFormVatEsLineType;
      handleChangeVatEsLine(val, vatLineIndex, vatLineProp, vatLineKey);
    } else if (key.startsWith('ESLine')) {
      const esLineSplit = key.split('_');
      const esLineKey = esLineSplit[0].replace('Line', '');
      const esLineIndex = parseInt(esLineSplit[1]) - 1;
      const esLineProp = esLineSplit[2] as keyof DocumentEditFormVatEsLineType;
      handleChangeVatEsLine(val, esLineIndex, esLineProp, esLineKey);
    } else if (key.startsWith('line')) {
      const lineSplit = key.split('_');
      if (lineSplit[2] === 'lineExtraCost') {
        handleLinesOnBlur(Boolean(val), parseInt(lineSplit[1]), lineSplit[2]);
      } else {
        handleChangeLines(val, parseInt(lineSplit[1]), lineSplit[2]);
      }
    } else {
      handleChangeMain(selectedTextFieldRef.current, val);
    }
    
    if (val !== '') {
      setFieldTrustScores({
        ...fieldTrustScoresRef.current,
        [key]: 2
      });
    }
  };

  const calculateFieldTrustscores = useCallback(() => {
    setFieldTrustScores(getFieldTrustScores(headerFields, doc.trustScores));
  }, [headerFields, doc]);

  useEffect(() => {
    calculateFieldTrustscores();
  }, [calculateFieldTrustscores]);

  useEffect(() => {
    if (annotateSelectedField && annotateFieldStart) {
      if (annotateFieldsTime[annotateSelectedField] !== undefined) {
        setAnnotateFieldsTime({
          ...annotateFieldsTime,
          [annotateSelectedField]: annotateFieldsTime[annotateSelectedField] + (new Date().getTime() - annotateFieldStart)
        });
      }
    }
    if (selectedTextField.key === '') {
      setAnnotateSelectedField(null);
      setAnnotateFieldStart(null);
    } else {
      if (selectedTextField.key.includes('VATLine') || selectedTextField.key.includes('ESLine')) {
        const splitted = selectedTextField.key.split('_');
        setAnnotateSelectedField(annotateTimeVatEsMap[`${splitted[0]}${splitted[2]}`]);
      } else if (selectedTextField.key.startsWith('line_')) {
        const splitted = selectedTextField.key.split('_');
        setAnnotateSelectedField(`${splitted[2]}Time`);
      } else {
        setAnnotateSelectedField(`${selectedTextField.key}Time`);
      }
      setAnnotateFieldStart(new Date().getTime());
    }
  }, [selectedTextField.key]);

  useEffect(() => {
    if(assistantID){
      setLoading(true);
      if (!authService.validateToken()) {
        enqueueSnackbar(t('PROCYS_LOGIN_SESSION_EXPIRED'), {
          variant: 'error',
          autoHideDuration: 5000
        });
        setTimeout(() => {
          authService.logout(LOGIN_PANEL_URL);
        }, 2000);
        return;
      }
      loadData();
    }
  }, [assistantID]);

  useEffect(() => {
    const termsCandidates = qbTerms.map((t: {code: string, name: string}) => t.code);
    const termsVal = details.terms || '';
    const found = termsCandidates.some((r: string) => termsVal === r);
    setDetailsXML({
      ...detailsXML,
      terms: termsCandidates,
    });
    setDetailsCandidates(getDocumentEditFormCandidates(headerFields, {
      ...detailsXML,
      terms: found ? termsCandidates : [termsVal, ...termsCandidates],
    }, detailsRef.current));
  }, [qbTerms]);

  useEffect(() => {
    if (headerFields.length > 0) {
      setDetails(getDocumentEditFormValues(user, doc, headerFields));
      setDetailsCandidates(getDocumentEditFormCandidates(headerFields, detailsXML, getDocumentEditFormValues(user, doc, headerFields)));
    }
    _setDoNotPay(Boolean(doc.doNotPay));
    if (doc.lines) {
      setLineDetails(doc.lines);
    }
    if (doc.vat) {
      setVatLines(doc.vat?.map((v: DocumentVatType) => ({
        ...v,
        taxName: v.taxName || '',
        amount: v.vatAmount,
        rate: v.vatRate,
        error: false,
        invalid: false
      })));
    }
    if (doc.esLines) {
      setEsLines(doc.esLines?.map((v: DocumentEsLineType) => ({
        ...v,
        amount: v.esAmount,
        rate: v.esRate,
        error: false,
        invalid: false
      })));
    }
    setShowDetails(prev => ({
      ...prev,
      VAT: doc.vat?.length > 0,
      ES: doc.esLines?.length > 0,
    }));
    setTags(doc.documentTags?.split(',').map((t: string) => t.trim()) || []);
  }, [doc, headerFields]);

  useEffect(() => {
    const renderedFieldIds = renderedFields.map((f) => f.key);
    const renderedLineFields = lineFields.filter((f) => f.isAvailable && f.isActive);
    const renderedLineFieldIds: string[] = [];

    for (let i = 0; i < lineDetails.length; i++) {
      renderedLineFields.forEach((f) => {
        renderedLineFieldIds.push(`line_${lineDetails[i].line}_${f.key}`);
      });
    }

    const handleKeyDown = (e: KeyboardEvent) => {
      if (e.key === 'Escape') {
        revertTipText(selectedTextFieldRef.current.key, selectedTextFieldRef.current.dataType, tipOriginalValue.current);
        setSelectedTextField({
          key: '',
          assistantKey: '',
          label: '',
          appDbField: '',
          dataType: '',
          exportMapKey: '',
          position: -1,
        });
      } else if (e.key === 'Tab') {
        e.preventDefault();
        revertTipText(selectedTextFieldRef.current.key, selectedTextFieldRef.current.dataType, tipOriginalValue.current);
        const currKey = selectedTextFieldRef.current.key;
        const index = renderedFieldsRef.current.findIndex((field) => field.key === currKey);
        if (index === -1 || (e.shiftKey && index === 0) || (!e.shiftKey && index === renderedFieldsRef.current.length - 1)) {
          setSelectedTextField({
            key: '',
            assistantKey: '',
            label: '',
            appDbField: '',
            dataType: '',
            exportMapKey: '',
            position: -1,
          });
          return;
        }
        let newIndex = index + 1;
        if (e.shiftKey) {
          newIndex = index - 1;
        }
        const field = renderedFieldsRef.current[newIndex];

        const element = document.getElementById(field.key);
        if (element) element.focus();
        setSelectedTextField(field);
      }
    };

    const handleClick = (e: MouseEvent) => {
      const target = e.target as HTMLInputElement;
      if (!(target?.id.includes('highlight-')
        || lineColumnsReprocess.includes(target?.id)
        || ["tip__textarea",
          "Tip__submit",
          "Tip__cancel",
          "PdfHighlighter",
          ...renderedFieldIds,
          ...renderedLineFieldIds,
        ].some((elemId) => elemId === target?.id))) {
        if (!getIsError(selectedTextFieldRef.current.key, selectedTextFieldRef.current.dataType, detailsRef.current[selectedTextFieldRef.current.key], user)) {
          setFieldTrustScores({
            ...fieldTrustScoresRef.current,
            [selectedTextFieldRef.current.key]: 2
          });
        }
        revertTipText(selectedTextFieldRef.current.key, selectedTextFieldRef.current.dataType, tipOriginalValue.current);
        setSelectedTextField({
          key: '',
          assistantKey: '',
          label: '',
          appDbField: '',
          dataType: '',
          exportMapKey: '',
          position: -1,
        });
      }
    };

    document.addEventListener('keydown', handleKeyDown);
    document.addEventListener('click', handleClick);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
      document.removeEventListener('click', handleClick);
    };
  }, [renderedFields, user]);

  const calculateRenderedFields = useCallback(() => {
    const renderedHeaderFields = headerFields.filter((f) => f.isAvailable && f.isActive);
    const renderedLineFields = lineFields.filter((f) => f.isAvailable && f.isActive && f.key !== 'line');
    const newRenderedFields: FieldType[] = [];
    for (let i = 0; i < renderedHeaderFields.length; i++) {
      const field = renderedHeaderFields[i];
      if (!senderRelatedFields.includes(field.key) && !receiverRelatedFields.includes(field.key)) {
        newRenderedFields.push(renderedHeaderFields[i]);
        if (field.key === 'supplier' && Boolean(showDetails.sender)) {
          const senderFields: FieldType[] = [];
          senderRelatedFields.forEach((f) => {
            const senderRelatedField = renderedHeaderFields.find((field) => field.key === f);
            if (senderRelatedField) {
              senderFields.push(senderRelatedField);
            }
          });
          senderFields.sort((a, b) => a.position - b.position);
          newRenderedFields.push(...senderFields);
        }
        if (field.key === 'receiver' && Boolean(showDetails.receiver)) {
          const receiverFields: FieldType[] = [];
          receiverRelatedFields.forEach((f) => {
            const receiverRelatedField = renderedHeaderFields.find((field) => field.key === f);
            if (receiverRelatedField) {
              receiverFields.push(receiverRelatedField);
            }
          });
          receiverFields.sort((a, b) => a.position - b.position);
          newRenderedFields.push(...receiverFields);
        }
      }
      if (field.key === 'vatAmount' && Boolean(showDetails.VAT)) {
        vatLines.forEach((v) => {
          newRenderedFields.push({
            key: `VATLine_${v.line}_taxName`,
            appDbField: 'tax_name',
            assistantKey: `VAT_line_${v.line}_tax_name`,
            dataType: 'text',
            exportMapKey: vatLineFieldMapping.taxName,
            label: `${t(vatLineLabels.taxName)} ${v.line}`,
            position: -1,
          });
          newRenderedFields.push({
            key: `VATLine_${v.line}_baseAmount`,
            appDbField: 'base_amount',
            assistantKey: `VAT_line_${v.line}_base_amount`,
            dataType: 'float',
            exportMapKey: vatLineFieldMapping.baseAmount,
            label: `${t(vatLineLabels.baseAmount)} ${v.line}`,
            position: -1,
          });
          newRenderedFields.push({
            key: `VATLine_${v.line}_rate`,
            appDbField: 'tax_rate',
            assistantKey: `VAT_line_${v.line}_rate`,
            dataType: 'float',
            exportMapKey: vatLineFieldMapping.vatRate,
            label: `${t(vatLineLabels.rate)} ${v.line}`,
            position: -1,
          });
          newRenderedFields.push({
            key: `VATLine_${v.line}_amount`,
            appDbField: 'tax_amount',
            assistantKey: `VAT_line_${v.line}_amount`,
            dataType: 'float',
            exportMapKey: vatLineFieldMapping.vatAmount,
            label: `${t(vatLineLabels.amount)} ${v.line}`,
            position: -1,
          });
        });
      }
      if (field.key === 'equivalenceSurchargeAmount' && Boolean(showDetails.ES)) {
        esLines.forEach((v) => {
          newRenderedFields.push({
            key: `ESLine_${v.line}_baseAmount`,
            appDbField: 'base_amount',
            assistantKey: `ES_line_${v.line}_base_amount`,
            dataType: 'float',
            exportMapKey: esLineFieldMapping.baseAmount,
            label: `${t(esLineLabels.baseAmount)} ${v.line}`,
            position: -1,
          });
          newRenderedFields.push({
            key: `ESLine_${v.line}_rate`,
            appDbField: 'es_rate',
            assistantKey: `ES_line_${v.line}_rate`,
            dataType: 'float',
            exportMapKey: esLineFieldMapping.esRate,
            label: `${t(esLineLabels.rate)} ${v.line}`,
            position: -1,
          });
          newRenderedFields.push({
            key: `ESLine_${v.line}_amount`,
            appDbField: 'es_amount',
            assistantKey: `ES_line_${v.line}_amount`,
            dataType: 'float',
            exportMapKey: esLineFieldMapping.esAmount,
            label: `${t(esLineLabels.amount)} ${v.line}`,
            position: -1,
          });
        });
      }
    }
    if (viewLines) {
      for (let i = 0; i < lineDetails.length; i++) {
        renderedLineFields.forEach((f, j) => {
          newRenderedFields.push({
            key: `line_${lineDetails[i].line}_${f.key}`,
            appDbField: f.appDbField,
            assistantKey: `line_${lineDetails[i].line}_${f.key}`,
            dataType: f.dataType,
            label: `${t(f.label)} ${lineDetails[i].line}`,
            exportMapKey: f.exportMapKey,
            position: f.position || j + 1,
          });
        });
      }
    }
    setRenderedFields(newRenderedFields);
  }, [headerFields, lineFields, lineDetails, vatLines, esLines, showDetails.VAT, showDetails.ES, showDetails.sender, showDetails.receiver, viewLines]);

  useEffect(() => {
    calculateRenderedFields();
  }, [calculateRenderedFields]);

  const getFieldsForManageFields = () => {
    if (manageFieldsEntity === 'invoice-line') {
      dispatch(
        fetchLineFields(
          user?.companyID || '',
          user?.userRole === userRoles.annotator ? doc.assistantID : undefined
        ) as unknown as UnknownAction
      );
    } else {
      dispatch(
        fetchHeaderFields(user?.companyID || '',
          headerEntity || '',
          user?.userRole === userRoles.annotator ? doc.assistantID : undefined
        ) as unknown as UnknownAction
      );
    }
  };

  useEffect(()=>{
    let resizeableDiv = fieldsContainerRef.current as HTMLDivElement;
    let resizeRight = resizeRef.current as HTMLDivElement;
    let styles = window.getComputedStyle(resizeableDiv);
    let width = parseInt(styles.width,10);
    let x = 68 + width;
    let preX = x;

    const onMouseMoveRightResize = (e: MouseEvent)=>{
      if(isMouseDownRef.current){ //if the mouse remains down
        let newX = e.clientX;
        let maxWidth = window.innerWidth - 65;
        styles = window.getComputedStyle(resizeableDiv);
        let newWidth = parseInt(styles.width,10) ;
        if(newX > preX) newWidth = newWidth + (newX - (newWidth + 75)) + 2; //check if the cursor is moving right
        else newWidth = newWidth + (newX - (newWidth + 76)); //check if the cursor is moving left

        if(newWidth > Math.floor(maxWidth/2)) return; //check if width is at helf the parent width
        resizeableDiv.style.width = `${newWidth}px`;        
        preX = newX;
      }
    }

    const onMouseUpRightResize = () =>{
      //when the the mouse is up.
      setIsMouseDown(false);
      document.addEventListener('mousemove',(e:MouseEvent)=>{
        onMouseMoveRightResize(e);
      });
    }

    const onMouseDownRightResize = (e: MouseEvent) =>{
      // When the small resize component is clicked down
      if(e.target === resizeRight) setIsMouseDown(true);
      document.addEventListener('mousemove',(e:MouseEvent)=>{
        //when the mouse is moved when the small resize component is still clicked down.
        onMouseMoveRightResize(e);
      })
      document.addEventListener('mouseup',()=>{
        //when the mouse is moved up
        setIsMouseDown(false);
        onMouseUpRightResize();
      });
    }
    //if the small resize component is available
    if(resizeRight) resizeRight.addEventListener('mousedown',onMouseDownRightResize)

    return ()=>{
      if(resizeRef) resizeRight.removeEventListener('mousedown',onMouseDownRightResize);
    }
       
  },[fieldsContainerRef.current, resizeRef.current])

  // To handle tax number/vat number switch
  const saveFieldsSelection = async (newField: string, oldField: string) => {
    const oldFieldObj = headerFields.find((f) => f.key === oldField);
    const selectedFields = [
      ...headerFields.filter((f) => f.isAvailable && f.isActive)
        .map((f) => ({name: f.key, position: f.position}))
        .filter((field) => field.name !== oldField),
      {name: newField, position: oldFieldObj?.position}
    ];
    try {
      let url = `${API.fields}/${headerEntity}/${user?.companyID}`;
      if (user?.userRole === userRoles.annotator) {
        url += `?docID=${doc.assistantID}`;
      }
      const response = await Axios.post(
        url,
        { fields: selectedFields },
        axiosHeaders(localStorage.getItem('PROCYS_accessToken'))
      );
      if (response.data.success) {
        getFieldsForManageFields();
        enqueueSnackbar(
          t('SAVE_FIELDS_SUCCESS'),
          {
            variant: 'success',
            autoHideDuration: 5000
          }
        );
      }
    } catch (e) {
      const error = e as CustomError;
      let errorMessage = appendContactSupport(window.config.support_email, t('SAVE_FIELDS_FAILURE'), t);
      if (error && error.response && error.response.data) {
        errorMessage = t(error.response.data.i18n || '');
      }

      enqueueSnackbar(
        errorMessage,
        {
          variant: 'error',
          autoHideDuration: 5000
        }
      );
    }
  };

  const setStatusChangedRef = (val: boolean) => {
    statusChangedRef.current = val;
  };

  const getInvoiceAfterStatusChange = async () => {
    const id = assistantID;
    try {
      const resp = await Axios.get(
        `${API.getInvoiceByID}${id}`,
        axiosHeaders(localStorage.getItem('PROCYS_accessToken'))
      );
      if (resp.data.success) {
        const newInvoice = resp.data.data;
        setDoc({
          ...doc,
          status: newInvoice.status,
          owner: newInvoice.owner,
          ownerName: newInvoice.ownerName,
          exportFailReason: newInvoice.exportFailReason,
        });
      } else {
        history.push('/documents');
      }
    } catch (e) {
      const error = e as CustomError;
      if (error?.response?.data?.i18n) {
        enqueueSnackbar(getLocalisedErrorString(error.response.data.i18n, t), {
          variant: 'error',
          autoHideDuration: 5000
        });
      }
      history.push('/documents');
    }
  };

  const handleStatusChange = async (status: number, assistantID: string) => {
    if (status === 900) {
      setOpenDeleteConf(true);
      return;
    }

    if (status === 501 && !chooseValidatorOpen) {
      setChooseValidatorOpen(true);
      return;
    }
    if (chooseValidatorOpen) {
      setChooseValidatorOpen(false);
    }

    setStatusChangeLoading(true);

    try {
      const body : StatusChangeRequest = {
        assistantID: parseInt(assistantID, 10),
        toStatus: status.toString()
      };

      if (status === 501 && selectedValidator?.email && selectedValidator.email !== doc.owner) {
        body.assignee = selectedValidator.email;
      }

      const response = await Axios.post(
        API.documentStatus,
        body,
        axiosHeaders(localStorage.getItem('PROCYS_accessToken'))
      );
      if (response.data.success) {
        await getDocument(true);
        enqueueSnackbar(status === 501
          ? t('INVOICE_STATUS_CHANGE_TO_VALIDATE')
          : (user?.isDocunecta && status === 503) ? t('DOCUNECTA_EXPORT_SUCCESS')
            : t('PROCYS_STATUS_CHANGE_SUCCESS'), {
          variant: 'success',
          autoHideDuration: 5000
        });
        if (status === 501) {
          sendFeedback(API.feedbackLogs, 'send to validate', user?.email, user?.companyID);
        }
        if (status === 502) {
          sendFeedback(API.feedbackLogs, 'validate invoice', user?.email, user?.companyID);
        }
        if (status === 503) {
          sendFeedback(API.feedbackLogs, 'invoice export', user?.email, user?.companyID);
          const currentUrlParams = new URLSearchParams(window.location.search);
          if (!user?.exported || user?.exported === 0) {
            currentUrlParams.set('feedback-first-export', 'success');
            history.push(`${window.location.pathname}?${currentUrlParams.toString()}`);
          }
          if (user?.exported === 9) {
            currentUrlParams.set('feedback-tenth-export', 'success');
            history.push(`${window.location.pathname}?${currentUrlParams.toString()}`);
          }
        }
        setStatusChangedRef(true);
        if (isAutoNavigationAllowed && nextInvoice) {
          navigateInvoice(nextInvoice, 1);
        }
      }

      setStatusChangeLoading(false);
    } catch (e) {
      const error = e as CustomError;
      enqueueSnackbar(getLocalisedErrorString(error?.response?.data?.i18n, t)
        || appendContactSupport(window.config.support_email, t((user?.isDocunecta && status === 503)
          ? 'DOCUNECTA_EXPORT_FAILURE' : 'INVOICE_STATUS_CHANGE_FAILED'), t),
      {
        variant: 'error',
        autoHideDuration: 5000
      });
      getInvoiceAfterStatusChange();
      setStatusChangeLoading(false);
    }
  };

  const handleOpenManageFields = (entity: string) => {
    if (showManageFields) {
      return;
    }
    setManageFieldsEntity(entity);
    setShowManageFields(true);
    pauseInvoiceAnnotateTime();
  };

  const handleCloseManageFields = () => {
    setShowManageFields(false);
    restartInvoiceAnnotateTime();
  };

  const openLinesComponent = () => {
    setViewLines(true);
  };

  const closeLinesComponent = () => {
    setViewLines(false);
  };

  const openInfoModal = () => {
    setShowInfoModal(true);
  };

  const closeInfoModal = () => {
    setShowInfoModal(false);
  };

  const onCancelDelete = () => {
    setOpenDeleteConf(false);
  };

  const handleChange = (val: string, prop: string) => {
    setIsSubmitClicked(false);
    setDetails({
      ...detailsRef.current,
      [prop]: val,
    });
  };

  const handleChangeMultiple = (values: string[], props: string[]) => {
    setIsSubmitClicked(false);
    const newDetails = { ...detailsRef.current };
    for (let i = 0; i < values.length; i++) {
      newDetails[props[i]] = values[i];
    }
    setDetails(newDetails);
  };

  const handleChangeCandidates = (name: string, val: string) => {
    const candidates = [...detailsXML[name]];
    const found = candidates.some(c => {
      return c === val;
    });
    const newCandidates = !found ? [val, ...candidates] : candidates;

    setDetailsCandidates({
      ...detailsCandidates,
      [name]: newCandidates
    });
  };

  const handleChangeMultipleCandidates = (names: string[], vals: string[]) => {
    const d = {...detailsCandidates};
    for (let i = 0; i < names.length; i++) {
      const name = names[i];
      const val = vals[i];
      const candidates = [...detailsXML[name]];
      const found = candidates.some(c => c === val);
      const newCandidates = !found ? [val, ...candidates] : candidates;

      d[name] = newCandidates;
    }

    setDetailsCandidates(d);
  };

  const handleChangeMain = async (field: FieldType, value: string | null) => {
    const { key: prop, assistantKey: name, dataType } = field;
    let formattedVal = value || '';
    if (dataType === 'text' && leadingWhiteSpace.test(formattedVal)) {
      formattedVal = formattedVal.trimStart();
    }

    if (prop === 'supplier') {
      if (details.supplier !== formattedVal && formattedVal) {
        const supp = await getSupplierByCode(formattedVal);
        const desc = getDescriptionFromTFSupplier(
          supp?.invoiceDescriptionRule || supplier?.invoiceDescriptionRule || '',
          details,
          supplier,
        );
        const lineDesc = getDescriptionFromTFSupplier(
          supp?.invoiceLineDescriptionRule || supplier?.invoiceLineDescriptionRule
            || supp?.invoiceDescriptionRule || supplier?.invoiceDescriptionRule || '',
          details,
          supplier,
        );

        let lines = [...lineDetailsRef.current];
        if (lines.length > 0) {
          if (isTwinfield) {
            const line = lines[0];
            line.lineDescription = lineDesc;
            line.lineGLAccount = supp?.generalLedgerAccLine || line.lineGLAccount;
            line.lineVatGroup = supp?.vatGroup || line.lineVatGroup;
            line.lineCostCenter = supp?.costCenter || line.lineCostCenter;
            line.lineProject = supp?.project || line.lineProject;
            line.lineTax = (await calculateVAT(line.lineVatGroup || '', line.lineAmount || '', API.vatCodeByCode, decimalSeparator)).toString();
            lines.shift();

            lines = [line, ...lines];
          }
          const supplierProducts = supp?.inventories || [];
          for (let i = 0; i < lines.length; i++) {
            const line = lines[i];
            if (line.lineArticle && supplierProducts.some((p: SupplierInventoryType) => p.article === line.lineArticle)) {
              const supplierProduct = supplierProducts.find((p: SupplierInventoryType) => p.article === line.lineArticle);
              line.lineArticleClient = supplierProduct?.articleClient || line.lineArticleClient;
              line.lineUnitMeasurement = supplierProduct?.unitMeasurement || line.lineUnitMeasurement;
            } else if (line.lineArticleClient && supplierProducts.some((p: SupplierInventoryType) => p.articleClient === line.lineArticleClient)) {
              const supplierProduct = supplierProducts.find((p: SupplierInventoryType) => p.articleClient === line.lineArticleClient);
              line.lineArticle = supplierProduct?.article || line.lineArticle;
              line.lineUnitMeasurement = supplierProduct?.unitMeasurement || line.lineUnitMeasurement;
            }
          }
        }

        const description = isTwinfield ? desc : details.description || '';
        const doNotPay = !supp?.autoPaymentStatus || supp?.autoPaymentStatus.toLowerCase() === supplierData.apStatuses[2].code.toLowerCase();
        const currency = supp?.defaultCurrency || details.currency || '';

        const valArr: string[] = [formattedVal, description, doNotPay, currency];
        const propArr: string[] = ['supplier', 'description', 'doNotPay', 'currency'];
        const valXmlArr: string[] = [currency];
        const nameArr: string[] = ['currency'];

        // Populate the sender company and VAT if it is available via supplier
        if (supp?.creditorName) {
          valArr.push(supp.creditorName);
          propArr.push('senderCompany');
          valXmlArr.push(supp.creditorName);
          nameArr.push('sender_company');
        }
        if (supp?.vatNumber) {
          propArr.push('senderCountryCode');
          propArr.push('senderVatNumber');
          nameArr.push('sender_country_code');
          nameArr.push('sender_VAT_number');
          const firstTwo = supp.vatNumber?.substring(0, 2);
          if (Object.keys(countries).some((c) => c === firstTwo.toUpperCase())) {
            valArr.push(firstTwo.toUpperCase());
            valArr.push(supp.vatNumber?.substring(2));
            valXmlArr.push(firstTwo.toUpperCase());
            valXmlArr.push(supp.vatNumber?.substring(2));
          } else if (supp?.country) {
            valArr.push(supp.country);
            valArr.push(supp.vatNumber);
            valXmlArr.push(supp.country);
            valXmlArr.push(supp.vatNumber);
          } else {
            valArr.push('ES');
            valArr.push(supp.vatNumber);
            valXmlArr.push('ES');
            valXmlArr.push(supp.vatNumber);
          }
        } else if (details.receiverVatNumber === details.senderVatNumber) {
          propArr.push('senderCountryCode');
          propArr.push('senderVatNumber');
          valArr.push('');
          valArr.push('');
          nameArr.push('sender_country_code');
          nameArr.push('sender_VAT_number');
          valXmlArr.push('');
          valXmlArr.push('');
        }

        if (user?.customisations.includes('calculateDeliveryDate') && !!parseInt(supp?.dueDays, 10) && !dateFieldRule) {
          const deliveryDate = addDays(
            details.documentDate || new Date(),
            parseInt(supp?.dueDays, 10),
            user.customisations.includes('dueDaysAsDayOfWeek')
          );
          valArr.push(deliveryDate);
          propArr.push('deliveryDate');
          valXmlArr.push(deliveryDate);
          nameArr.push('delivery_date');
        }

        if (custom2FieldInDocumentForm && supp?.custom2) {
          valArr.push(supp?.custom2);
          propArr.push('clientCode');
          valXmlArr.push(supp?.custom2);
          nameArr.push('client_code');
        }

        handleChangeMultiple(valArr, propArr);
        handleChangeMultipleCandidates(nameArr, valXmlArr);
        return;
      }

      handleChange(formattedVal, prop);
      return;
    }

    if (prop === 'receiver') {
      if (details.receiver !== formattedVal && formattedVal) {
        const client = await getSupplierByCode(formattedVal);

        const valArr: string[] = [formattedVal];
        const propArr: string[] = ['receiver'];
        const valXmlArr: string[] = [];
        const nameArr: string[] = [];

        // Populate the sender company and VAT if it is available via supplier
        if (client?.creditorName) {
          valArr.push(client.creditorName);
          propArr.push('receiverCompany');
          valXmlArr.push(client.creditorName);
          nameArr.push('receiver_company');
        }
        if (client?.vatNumber) {
          propArr.push('receiverCountryCode');
          propArr.push('receiverVatNumber');
          nameArr.push('receiver_country_code');
          nameArr.push('receiver_VAT_number');
          const firstTwo = client.vatNumber?.substring(0, 2);
          if (Object.keys(countries).some((c) => c === firstTwo.toUpperCase())) {
            valArr.push(firstTwo.toUpperCase());
            valArr.push(client.vatNumber?.substring(2));
            valXmlArr.push(firstTwo.toUpperCase());
            valXmlArr.push(client.vatNumber?.substring(2));
          } else if (client?.country) {
            valArr.push(client.country);
            valArr.push(client.vatNumber);
            valXmlArr.push(client.country);
            valXmlArr.push(client.vatNumber);
          } else {
            valArr.push('ES');
            valArr.push(client.vatNumber);
            valXmlArr.push('ES');
            valXmlArr.push(client.vatNumber);
          }
        } else if (details.receiverVatNumber === details.senderVatNumber) {
          propArr.push('receiverCountryCode');
          propArr.push('receiverVatNumber');
          valArr.push('');
          valArr.push('');
          nameArr.push('receiver_country_code');
          nameArr.push('receiver_VAT_number');
          valXmlArr.push('');
          valXmlArr.push('');
        }

        handleChangeMultiple(valArr, propArr);
        handleChangeMultipleCandidates(nameArr, valXmlArr);
        return;
      }

      handleChange(formattedVal, prop);
      return;
    }

    if (prop === 'currency' && formattedVal) {
      handleChange(formattedVal, prop);
      return;
    }

    if ((prop === 'receiverCountryCode' || prop === 'senderCountryCode' || prop === 'countryIssued') && formattedVal) {
      handleChange(formattedVal, prop);
      return;
    }

    if (formattedVal) {
      if (prop === 'documentDate') {
        const d = formattedVal;
        let periodVal = details.period || '';

        const parts = d?.split('-') || [];
        if (!dateFieldRule && parts.length >= 3) {
          if (d.indexOf('-') === 2) {
            periodVal = `${parts[2].substring(0, 4)}/${parts[1]}`;
          } else if (d.indexOf('-') === 4) {
            periodVal = `${parts[0].substring(0, 4)}/${parts[1]}`;
          }
          handleChangeMultiple([periodVal, formattedVal], ['period', 'documentDate']);
          return;
        }
        handleChange(d, prop);
      } else if (prop === 'senderVatNumber' || prop === 'receiverVatNumber') {
        const firstTwo = formattedVal.substring(0, 2);
        if (Object.keys(countries).some((c) => c === firstTwo.toUpperCase())) {
          handleChangeMultiple(
            [firstTwo.toUpperCase(), formattedVal.substring(2)],
            [`${prop.replace('VatNumber', '')}CountryCode`, prop]
          );
        } else {
          handleChange(formattedVal, prop);
        }
      } else if (prop === 'amount') {
        if (details?.vatAmount) {
          const baseAmount = calculateBaseAmount(formattedVal, details.vatAmount, decimalSeparator);
          const valArr = [formattedVal, baseAmount];
          const propArr = ['amount', 'baseAmount'];
          const valXmlArr = [baseAmount];
          const nameArr = ['base_amount'];
          if (details?.discountAmount) {
            const discountRate = calculateDiscountRate(details.discountAmount, baseAmount, decimalSeparator);
            valArr.push(discountRate);
            propArr.push('discountRate');
            valXmlArr.push(discountRate);
            nameArr.push('discount_rate');
          }
          handleChangeMultiple(valArr, propArr);
          handleChangeMultipleCandidates(nameArr, valXmlArr);
        } else {
          handleChange(formattedVal, prop);
        }
      } else if (prop === 'vatAmount') {
        if (details?.amount) {
          const baseAmount = calculateBaseAmount(details.amount, formattedVal, decimalSeparator);
          const valArr = [formattedVal, baseAmount];
          const propArr = ['vatAmount', 'baseAmount'];
          const valXmlArr = [baseAmount];
          const nameArr = ['base_amount'];
          if (details?.discountAmount) {
            const discountRate = calculateDiscountRate(details.discountAmount, baseAmount, decimalSeparator);
            valArr.push(discountRate);
            propArr.push('discountRate');
            valXmlArr.push(discountRate);
            nameArr.push('discount_rate');
          }
          handleChangeMultiple(valArr, propArr);
          handleChangeMultipleCandidates(nameArr, valXmlArr);
        } else {
          handleChange(formattedVal, prop);
        }
      } else if (prop === 'discountAmount') {
        if (details?.amount && details?.vatAmount) {
          const baseAmount = calculateBaseAmount(details.amount, details.vatAmount, decimalSeparator);
          const discountRate = calculateDiscountRate(formattedVal, baseAmount, decimalSeparator);

          handleChangeMultiple([formattedVal, discountRate], ['discountAmount', 'discountRate']);
          handleChangeMultipleCandidates(['discount_rate'], [discountRate]);
        } else {
          handleChange(formattedVal, prop);
        }
      } else {
        handleChange(formattedVal, prop);
      }
      return;
    }
    handleChangeCandidates(name, formattedVal);
    handleChange(formattedVal, prop);
  };

  const handleChangeText = (field: FieldType, val: string) => {
    const { key: prop, assistantKey: name, dataType } = field;
    setIsSubmitClicked(false);
    let formattedVal = val;
    if (dataType === 'text' && leadingWhiteSpace.test(val)) {
      formattedVal = val.trimStart();
    }

    if (formattedVal) {
      if (prop === 'documentDate') {
        const d = formattedVal;
        let periodVal = details.period || '';

        const parts = d?.split('-') || [];
        if (!dateFieldRule && parts.length >= 3) {
          if (d.indexOf('-') === 2) {
            periodVal = `${parts[2].substring(0, 4)}/${parts[1]}`;
          } else if (d.indexOf('-') === 4) {
            periodVal = `${parts[0].substring(0, 4)}/${parts[1]}`;
          }
        }

        const valArr = [d, periodVal];
        const propArr = ['documentDate', 'period'];
        const valXmlArr = [d];
        const nameArr = ['date'];

        handleChangeMultiple(valArr, propArr);
        handleChangeMultipleCandidates(nameArr, valXmlArr);
      } else if (prop === 'amount' && details?.vatAmount) {
        const baseAmount = calculateBaseAmount(formattedVal, details.vatAmount, decimalSeparator);
        let discountRate = details.discountRate || '';

        if (details?.discountAmount) {
          discountRate = calculateDiscountRate(details.discountAmount, baseAmount, decimalSeparator);
        }

        const valArr = [formattedVal, discountRate];
        const propArr = ['amount', 'discountRate'];
        const valXmlArr = [formattedVal, discountRate];
        const nameArr = ['amount', 'discount_rate'];

        handleChangeMultiple(valArr, propArr);
        handleChangeMultipleCandidates(nameArr, valXmlArr);
      } else if (prop === 'vatAmount' && details?.amount) {
        const baseAmount = calculateBaseAmount(details.amount, formattedVal, decimalSeparator);
        let discountRate = details.discountRate || '';
        if (details?.discountAmount) {
          discountRate = calculateDiscountRate(details.discountAmount, baseAmount, decimalSeparator);
        }

        const valArr = [formattedVal, discountRate];
        const propArr = ['vatAmount', 'discountRate'];
        const valXmlArr = [formattedVal, discountRate];
        const nameArr = ['vat_amount', 'discount_rate'];

        handleChangeMultiple(valArr, propArr);
        handleChangeMultipleCandidates(nameArr, valXmlArr);
      } else if (prop === 'discountAmount' && details?.amount && details?.vatAmount) {
        const baseAmount = calculateBaseAmount(details.amount, details.vatAmount, decimalSeparator);
        const discountRate = calculateDiscountRate(formattedVal, baseAmount, decimalSeparator)

        const valArr = [formattedVal, discountRate];
        const propArr = ['discountAmount', 'discountRate'];
        const valXmlArr = [formattedVal, discountRate];
        const nameArr = ['discount_amount', 'discount_rate'];

        handleChangeMultiple(valArr, propArr);
        handleChangeMultipleCandidates(nameArr, valXmlArr);
      } else if (prop === 'documentNumber') {
        const archiveNumber = `${doc.fileName} ${formattedVal}`;

        const valArr = [formattedVal, archiveNumber];
        const propArr = ['documentNumber', 'archiveNumber'];
        const valXmlArr = [formattedVal, archiveNumber];
        const nameArr = ['number', 'archive_number'];

        handleChangeMultiple(valArr, propArr);
        handleChangeMultipleCandidates(nameArr, valXmlArr);
      } else {
        handleChange(formattedVal, prop);
        handleChangeCandidates(name, formattedVal);
      }

      return;
    }

    handleChangeCandidates(name, val);
    handleChange(val, prop);
  };

  const handleChangeAmount = (newAmount: string) => {
    let isNotAvailable = true;
    for (let j = 0; j < detailsXML.amount.length; j++) {
      if (detailsXML.amount[j] === newAmount) {
        isNotAvailable = false;
      }
    }

    if (isNotAvailable) {
      handleChangeCandidates('amount', newAmount);
    }

    handleChangeMain({
      key: 'amount',
      assistantKey: 'amount',
      label: 'INVOICE_EDIT_FORM_AMOUNT',
      appDbField: 'amount',
      dataType: 'float',
      exportMapKey: 'INVOICE_AMOUNT',
      position: -1,
    }, newAmount);
  };

  const handleChangeVatEsLine = (
    val: string,
    i: number,
    prop: keyof DocumentEditFormVatEsLineType,
    key: string,
  ) => {
    let _lines: DocumentEditFormVatEsLineType[] = vatLinesRef.current;
    let _setLines: (_val: DocumentEditFormVatEsLineType[]) => void = setVatLines;
    if (key === 'ES') {
      _lines = esLinesRef.current;
      _setLines = setEsLines;
    }
    setIsSubmitClicked(false);

    const selectedLine = _lines[i];
    const before = _lines.slice(0, i);
    let after: DocumentEditFormVatEsLineType[] = [];
    if (_lines.length > 1 && i < _lines.length) {
      after = _lines.slice(i + 1);
    }
    //@TODO: Remove as never and handle this properly
    if (Object.keys(selectedLine).length > 0 && typeof selectedLine[prop] !== 'number' && typeof selectedLine[prop] !== 'boolean') {
      selectedLine[prop] = val as never;
    }
    selectedLine.error = false;
    selectedLine.invalid = false;
    if (prop === 'baseAmount' && selectedLine.rate !== '' && val !== '') {
      selectedLine.amount = calculateVatAmount(val, selectedLine.rate, decimalSeparator);
    }
    if (prop === 'rate' && selectedLine.baseAmount !== '' && val !== '') {
      selectedLine.amount = calculateVatAmount(selectedLine.baseAmount, val, decimalSeparator);
    }
    if (prop === 'amount') {
      const calculatedVatAmount = calculateVatAmount(selectedLine.baseAmount, selectedLine.rate, decimalSeparator);
      if (selectedLine.baseAmount === '' || selectedLine.rate === '' || parseFloat(val) !== parseFloat(calculatedVatAmount)) {
        selectedLine.error = true;
      }
    }
    if (['baseAmount', 'rate', 'amount'].some((k) => {
      const vl = selectedLine[k as keyof DocumentEditFormVatEsLineType];
      return typeof vl === 'string' && !amountRegex.test(vl);
    })) {
      selectedLine.invalid = true;
    }
    _setLines([...before, selectedLine, ...after]);
  };

  const handleChangeLines = (value: string, line: number, prop: string) => {
    const val = value;
    if (leadingWhiteSpace.test(val)) {
      val.trimStart();
    }
    const newLineDetails = lineDetailsRef.current.map((l) => {
      if (l.line === line) {
        return {
          ...l,
          [prop]: val
        };
      }
      return l;
    });
    setLineDetails(newLineDetails);
  };

  const handleLinesOnBlur = (value: string | boolean, line: number, prop: string) => {
    let updatedLine: DocumentLineType = { line };
    const val = value;
    if (typeof val === 'string' && leadingWhiteSpace.test(val)) {
      val.trimStart();
    }
    let product: SupplierInventoryType | undefined;
    if (supplier) {
      const supplierProducts = supplier?.inventories || [];
      if (prop === 'lineArticle' && supplierProducts.some((p) => p.article === val)) {
        product = supplierProducts.find((p) => p.article === val);
      }
      if (prop === 'lineArticleClient' && supplierProducts.some((p) => p.articleClient === val)) {
        product = supplierProducts.find((p) => p.articleClient === val);
      }
    }
    lineDetailsRef.current.forEach((l) => {
      if (l.line === line) {
        updatedLine = {
          ...l,
          [prop]: val
        };
        if ((prop === 'lineArticle' || prop === 'lineArticleClient') && product) {
          updatedLine.lineArticle = product.article || '';
          updatedLine.lineArticleClient = product.articleClient || '';
          updatedLine.lineUnitMeasurement = product.unitMeasurement || '';
        }
      }
    });
    if (updatedLine.lineExtraCost === undefined) {
      updatedLine.lineExtraCost = false;
    }
    onUpdateLine(updatedLine, prop);
  };

  const onUpdateLine = async (line: DocumentLineType, prop: string) => {
    const renderedLineFields = lineFields.filter((f) => f.isActive);
    const lastLineFieldKey = renderedLineFields[renderedLineFields.length - 1].key;
    const newLines = [...lineDetailsRef.current];
    for (let i = 0; i < newLines.length; i++) {
      if ((newLines[i].id && line.id && newLines[i].id === line.id) || (newLines[i].id === undefined && newLines[i].line === line.line)) {
        if (newLines[i].lineExtraCost === undefined) {
          newLines[i].lineExtraCost = false;
        }

        if (newLines[i].lineAmount === line.lineAmount && newLines[i].lineExtraCost !== line.lineExtraCost && line.lineExtraCost) {
          handleChangeAmount(calculateLineAmountExtraCost1(details.amount, line.lineAmount, decimalSeparator));
        }

        if (newLines[i].lineAmount === line.lineAmount && newLines[i].lineExtraCost !== line.lineExtraCost && !line.lineExtraCost) {
          handleChangeAmount(calculateLineAmountExtraCost2(details.amount, line.lineAmount, decimalSeparator));
        }

        if (newLines[i].lineAmount !== line.lineAmount && line.lineExtraCost && newLines[i].lineExtraCost === line.lineExtraCost) {
          handleChangeAmount(calculateLineAmountExtraCost3(details.amount, line.lineAmount, newLines[i].lineAmount, decimalSeparator));
        }

        if (newLines[i].lineAmount !== line.lineAmount && !line.lineExtraCost && newLines[i].lineExtraCost !== line.lineExtraCost) {
          handleChangeAmount(calculateLineAmountExtraCost4(details.amount, newLines[i].lineAmount, decimalSeparator));
        }

        let lineTax = line.lineTax || '';
        if (isTwinfield && line.lineVatGroup && line.lineVatGroup !== 'No VAT') {
          lineTax = await calculateVAT(line.lineVatGroup, line.lineAmount || '', API.vatCodeByCode, decimalSeparator);
        }

        newLines[i] = newLineDetails(newLines[i].id, line, lineTax);

        setLineDetails(newLines);
        setIsSubmitClicked(false);
        if (line.line % 3 === 0 && lastLineFieldKey === prop) {
          handleSubmit(true);
        }
      }
    }
  };

  const onAddLineConfirm = async (line: DocumentLineType) => {
    if (line.lineExtraCost) {
      handleChangeAmount(calculateLineAmountExtraCost1(details.amount, line.lineAmount, decimalSeparator));
    }

    let lineTax = line.lineTax || '';
    if (isTwinfield && line.lineVatGroup && line.lineVatGroup !== 'No VAT') {
      lineTax = await calculateVAT(line.lineVatGroup, line.lineAmount || '', API.vatCodeByCode, decimalSeparator);
    }

    const newLine = newLineDetails(line.id, line, lineTax)

    setLineDetails([...lineDetails, newLine]);
    setIsSubmitClicked(false);
  };

  const onDeleteLineConfirm = (line: DocumentLineType) => {
    // @TODO: implement delete line modal
    const lines = [];
    let count = 1;
    for (let i = 0; i < lineDetails.length; i++) {
      if ((line.id === undefined && line.line === lineDetails[i].line) || (line.id && lineDetails[i].id === line.id)) {
        if (line.lineExtraCost) {
          handleChangeAmount(calculateLineAmountExtraCost2(details.amount, line.lineAmount, decimalSeparator));
        }
      } else {
        // Update the line property??
        const line = { ...lineDetails[i] };
        line.line = count;
        count++;

        lines.push(line);
      }
    }

    if (user?.isDocunecta && makroSuppliers.includes(details.senderVatNumber || '')) {
      details.subtotal = calculateSubtotal(lines, decimalSeparator);
    }

    setLineDetails(lines);
    setIsSubmitClicked(false);
  };

  const adjustLinesOrder = (oldLine: number, newLine?: number) => {
    const existingLines = [...lineDetails];
    if (newLine === undefined) {
      return;
    }
    const newLines = [];
    if (oldLine > newLine) {
      for (let i = 0; i < existingLines.length; i++) {
        if (existingLines[i].line < oldLine && existingLines[i].line >= newLine) {
          existingLines[i].line += 1;
          newLines.push(existingLines[i]);
        } else {
          if (existingLines[i].line === oldLine) {
            existingLines[i].line = newLine;
            newLines.push(existingLines[i]);
          } else {
            newLines.push(existingLines[i]);
          }
        }
      }
    }

    if (oldLine < newLine) {
      for (let i = 0; i < existingLines.length; i++) {
        if (existingLines[i].line > oldLine && existingLines[i].line <= newLine) {
          existingLines[i].line -= 1;
          newLines.push(existingLines[i]);
        } else {
          if (existingLines[i].line === oldLine) {
            existingLines[i].line = newLine;
            newLines.push(existingLines[i]);
          } else {
            newLines.push(existingLines[i]);
          }
        }
      }
    }

    if (user?.isDocunecta && makroSuppliers.includes(details.senderVatNumber || '')) {
      handleChange(calculateSubtotal(newLines, decimalSeparator), 'subtotal');
    }

    setLineDetails(newLines);
    setIsSubmitClicked(false);
  };

  const updateFieldBoundaries = async () => {
    const updatedFieldBoundaries: Record<string, Record<string, string>> = {};
    const candidateHighlights = highlightsRef.current.filter((highlight) => highlight.isCandidate);
    for (const highlight of candidateHighlights) {
      const { procysKey, position } = highlight;
      const { boundingRect, pageNumber } = position;
      if (procysKey && boundingRect) {
        updatedFieldBoundaries[`${procysKey}Boundary`] = {
          page: pageNumber.toString(),
          xmin: boundingRect.x1.toString(),
          ymin: boundingRect.y1.toString(),
          xmax: boundingRect.x2.toString(),
          ymax: boundingRect.y2.toString(),
          height: (boundingRect.y2 - boundingRect.y1).toString(),
          width: (boundingRect.x2 - boundingRect.x1).toString()
        }
      }
    }

    try {
      const resp = await Axios.put(
        `${API.getDocumentBoundaries}${doc.assistantID}`,
        updatedFieldBoundaries,
        axiosHeaders(localStorage.getItem('PROCYS_accessToken'))
      );
      if (resp.data.success && resp.data.data) {
        //
      }
    } catch (e) {
      //
    }

  };

  const handleSubmit = async (bgSave?: boolean) => {
    if (isSubmitClickedRef.current) {
      return;
    }
    setSubmitting(true);
    try {
      if (!authService.validateToken()) {
        setSubmitting(false);
        enqueueSnackbar(t('PROCYS_LOGIN_SESSION_EXPIRED'), {
          variant: 'error',
          autoHideDuration: 5000
        });
        setTimeout(() => {
          authService.logout(LOGIN_PANEL_URL);
        }, 2000);
        throw new Error(t('PROCYS_LOGIN_SESSION_EXPIRED'));
      }

      let timeSpent = 0;
      if (invoiceAnnotateStart) {
        timeSpent = invoiceAnnotateTime + (new Date().getTime() - Number(invoiceAnnotateStart));
      }
      setInvoiceAnnotateTime(timeSpent);

      await updateFieldBoundaries();

      const resp = await Axios.put(
        `${API.updateInvoice}`,
        {
          ...detailsRef.current,
          autoSave: bgSave,
          doNotPay,
          assistantID: doc.assistantID,
          id: doc.id,
          company: doc.company,
          email: doc.email,
          lines: lineDetailsRef.current,
          times: {
            ...annotateFieldsTimeRef.current,
            invoiceTime: timeSpent
          },
          trustScores: formatFieldTrustScores(fieldTrustScoresRef.current),
          vat: vatLinesRef.current
            .filter((vl) => (vl.baseAmount !== '0' || vl.rate !== '0' || vl.amount !== '0'))
            .map(({ error: _error, invalid: _invalid, ...vl }) => ({
              ...vl,
              vatRate: vl.rate,
              vatAmount: vl.amount
            })),
          esLines: esLinesRef.current
            .filter((vl) => (vl.baseAmount !== '0' || vl.rate !== '0' || vl.amount !== '0'))
            .map(({ error: _error, invalid: _invalid, ...vl }) => ({
              ...vl,
              esRate: vl.rate,
              esAmount: vl.amount
            })),
          documentTags: tags.join(', '),
          // sendLines,
        },
        axiosHeaders(localStorage.getItem('PROCYS_accessToken'))
      );

      if (resp.data.success) {
        setStatusChangedRef(true);
        setInvoiceAnnotateTime(0);
        setInvoiceAnnotateStart(new Date().getTime());
        setAnnotateFieldsTime(initializeAnnotateFieldTimes([...headerFields, ...lineFields]));
        setAnnotateSelectedField(null);
        setSubmitting(false);
        if (!bgSave) {
          enqueueSnackbar(t('INVOICE_EDIT_FORM_SAVE_SUCCESS'), {
            variant: 'success',
            autoHideDuration: 5000
          });
          if (moveToNextRef.current === -1) {
            navigateInvoice(previousInvoice, -1);
          } else if (moveToNextRef.current === 1 || (isAutoNavigationAllowed && nextInvoice !== null)) {
            navigateInvoice(nextInvoice, 1);
          }
          setMoveToNext(null);
        }
        await getDocument(bgSave);
      } else {
        setSubmitting(false);
        if (!bgSave) {
          enqueueSnackbar(appendContactSupport(window.config.support_email, t('INVOICE_EDIT_FORM_SAVE_FAIL'), t), {
            variant: 'error',
            autoHideDuration: 5000
          });
          throw new Error(t('INVOICE_EDIT_FORM_SAVE_FAIL'));
        }
      }

      if (!bgSave) setIsSubmitClicked(true);
    } catch (e) {
      setSubmitting(false);
      if (!bgSave) {
        const error = e as CustomError;
        if (error?.response?.data) {
          enqueueSnackbar(
            getLocalisedErrorString(error.response.data.i18n || appendContactSupport(window.config.support_email, t('INVOICE_EDIT_FORM_SAVE_FAIL'), t), t),
            {
              variant: 'error',
              autoHideDuration: 5000
            }
          );
        }
        throw new Error(t('INVOICE_EDIT_FORM_SAVE_FAIL'));
      }
    }
  };

  const navigateInvoice = (navTo: DocType | null, increment: number) => {
    const statusChanged = statusChangedRef.current;
    const queryVal = new URLSearchParams(history.location.search);
    const status = queryVal.get('status');
    const filters = queryVal.get('filters');
    const query = queryVal.get('query');
    const time = queryVal.get('time');
    const thisPage = queryVal.get('page');
    const limit = queryVal.get('limit');
    const supplierFilter = JSON.parse(queryVal.get('supplier_filter') || 'null');
    const ownerFilter = JSON.parse(queryVal.get('owner_filter') || 'null');
    const companyFilter = JSON.parse(queryVal.get('company_filter') || 'null');
    const startTime = queryVal.get('start_time');
    const endTime = queryVal.get('end_time');
    const failedToExport = JSON.parse(queryVal.get('failed_to_export') || 'false');
    const documentsSelected = JSON.parse(queryVal.get('documents_selected') || 'false');
    const invoicePage = JSON.parse(queryVal.get('document_page') || '1')
      + increment
      + (increment > 0 && statusChanged && status !== 'INVOICE_STATUS_ALL' ? -1 : 0);
    const total = JSON.parse(queryVal.get('total') || '1') + (statusChanged && status !== 'INVOICE_STATUS_ALL' ? -1 : 0);
    history.push(
      `/doc/${navTo?.assistantID}/edit?${status !== null
        ? `&status=${status}` : ''}${filters !== null ? `&filters=${filters}` : ''}${query !== null
        ? `&query=${query}` : ''}${time !== null ? `&time=${time}` : ''}${thisPage !== null
        ? `&page=${thisPage}` : ''}${limit !== null ? `&limit=${limit}` : ''}${supplierFilter
        ? `&supplier_filter=${supplierFilter}` : ''}${companyFilter ? `&company_filter=${companyFilter}` : ''}${ownerFilter
        ? `&owner_filter=${ownerFilter}` : ''}${startTime !== null ? `&start_time=${startTime}` : ''}${endTime !== null
        ? `&end_time=${endTime}` : ''}${failedToExport ? '&failed_to_export=true' : ''}${documentsSelected
        ? '&documents_selected=true' : ''}&document_page=${invoicePage}&total=${total}`
    );
    history.go(0);
  };

  const handleLinesDataForReprocessingChange = (highlight: IHighlight) => {
    let formattedKey = selectedTextField.key.replace('invoiceLineColumn_', '');
    if (formattedKey === 'lineDescription') {
      formattedKey = 'lineName';
    }
    setLinesDataForReprocessing((prev) => {
      if (prev[formattedKey]) {
        return {
          ...prev,
          [formattedKey]: [...prev[formattedKey], highlight]
        }
      }
      return prev;
    });
  };

  const resetLineBoundaries = (key: string) => {
    let formattedKey = key;
    if (formattedKey === 'lineDescription') {
      formattedKey = 'lineName';
    }

    const linesReprocessHighlights = Object.values(linesDataForReprocessing[formattedKey]);
    setHighlights(highlightsRef.current.filter((highlight) => !linesReprocessHighlights.some((hl) => hl.id === highlight.id)));
    setLinesDataForReprocessing({
      ...linesDataForReprocessing,
      [formattedKey]: []
    });
  };

  const sendLinesForReprocessing = async () => {
    setReprocessingLines(true);
    const data = {
      assistantID: doc.assistantID?.toString(),
      ...formatLinesReprocessingData(linesDataForReprocessing)
    };

    try {
      const response = await Axios.post(
        `${API.lineBoundaries}`,
        data,
        axiosHeaders(localStorage.getItem('PROCYS_accessToken'))
      );
      if (response.data.success && response.data.data.length > 0) {
        const linesReprocessHighlights = Object.values(linesDataForReprocessing).flat();
        setHighlights(highlightsRef.current.filter((highlight) => !linesReprocessHighlights.some((hl) => hl.id === highlight.id)));
        setLinesDataForReprocessing({
          linesTotal: [],
          lineAll: [],
          lineArticle: [],
          lineQuantity: [],
          lineAmount: [],
          lineName: []
        });
        const updatedLines: DocumentLineType[] = [];
        lineDetails.forEach((line) => {
          const updatedLine = { ...line };
          const reprocessedLine = response.data.data.find((l: DocumentLineType) => l.line === line.line);
          if (reprocessedLine) {
            updatedLine.lineArticle = reprocessedLine.lineArticle;
            updatedLine.lineQuantity = reprocessedLine.lineQuantity;
            updatedLine.lineAmount = reprocessedLine.lineAmount;
            updatedLine.lineDescription = reprocessedLine.lineDescription;
          }
          updatedLines.push(updatedLine);
        });
        if (response.data.data.length > lineDetails.length) {
          response.data.data.forEach((line: DocumentLineType) => {
            if (!lineDetails.find((l: DocumentLineType) => l.line === line.line)) {
              updatedLines.push(line);
            }
          });
        }
        setLineDetails(updatedLines);
        setReprocessingLines(false);
        enqueueSnackbar(
          t('SEND_LINE_BOUNDARIES_SUCCESS'),
          {
            variant: 'success',
            autoHideDuration: 5000
          }
        );
      }
    } catch (error) {
      setReprocessingLines(false);
      enqueueSnackbar(
        t('SEND_LINE_BOUNDARIES_FAILURE'),
        {
          variant: 'error',
          autoHideDuration: 5000
        }
      );
    }
  };

  return (
    <Grid sx={styles.root}>
      <Grid ref={fieldsContainerRef} sx={styles.documentDataContainer}>
        {showManageFields ? (
          <ManageFieldsNew
            entity={manageFieldsEntity}
            fields={manageFieldsEntity === 'invoice-line' ? lineFields : headerFields}
            parameterCompany={parameterCompany}
            docID={doc.assistantID}
            handleCloseManageFields={handleCloseManageFields}
            fetchFields={getFieldsForManageFields}
          />
        ) : (
          <DocumentFields
            fields={headerFields}
            fieldsToDisplay={(isPassportOrID || headerEntity === 'id') ? headerFields : [...headerFields, lineField]}
            lineFields={lineFields}
            supplierFields={supplierFields}
            doc={doc}
            details={details}
            detailsCandidates={detailsCandidates}
            lineDetails={lineDetails}
            statusChangeLoading={statusChangeLoading}
            previousInvoice={previousInvoice}
            nextInvoice={nextInvoice}
            suppliers={suppliers}
            qbTerms={qbTerms}
            isSubmitClicked={isSubmitClicked}
            fieldTrustScores={fieldTrustScores}
            fieldTrustScoresRef={fieldTrustScoresRef}
            parameterDocCompany={parameterDocCompany}
            doNotPay={doNotPay}
            vatLinesRef={vatLinesRef}
            esLinesRef={esLinesRef}
            tags={tags}
            selectedTextField={selectedTextField}
            submitting={submitting}
            showDetails={showDetails}
            selectedTextFieldRef={selectedTextFieldRef}
            headerEntity={headerEntity}
            isMouseDown={isMouseDown}
            isMouseDownRef={isMouseDownRef}
            setDoNotPay={setDoNotPay}
            handleChange={handleChange}
            handleChangeMain={handleChangeMain}
            handleChangeText={handleChangeText}
            handleChangeVatEsLine={handleChangeVatEsLine}
            handleSubmit={handleSubmit}
            setLoading={setLoading}
            setStatusChangedRef={setStatusChangedRef}
            getDocument={getDocument}
            getSupplierByCode={getSupplierByCode}
            navigateInvoice={navigateInvoice}
            findAllSuppliers={findAllSuppliers}
            openLinesComponent={openLinesComponent}
            handleStatusChange={handleStatusChange}
            handleOpenManageFields={handleOpenManageFields}
            openInfoModal={openInfoModal}
            saveFieldsSelection={saveFieldsSelection}
            setVatLines={setVatLines}
            setEsLines={setEsLines}
            setTags={setTags}
            setIsSubmitClicked={setIsSubmitClicked}
            setMoveToNext={setMoveToNext}
            handleChooseValidatorModalOpen={handleChooseValidatorModalOpen}
            setSelectedTextField={setSelectedTextField}
            setShowDetails={setShowDetails}
            setFieldTrustScores={setFieldTrustScores}
            pauseInvoiceAnnotateTime={pauseInvoiceAnnotateTime}
            restartInvoiceAnnotateTime={restartInvoiceAnnotateTime}
            handleClose={handleClose}
            moveNext={moveNext}
            movePrevious={movePrevious}
            disableMove={disableMove}
          />
        )}
        <Box ref={resizeRef} sx={styles.resizeContainer} ></Box>
      </Grid>
      <Grid className="dashed-grid-paper" sx={styles.documentDisplayContainer}>
        <PdfViewer
          pdfDoc={doc}
          details={details}
          xml={xml}
          viewerHeight={pdfViewerHeight}
          renderedFields={renderedFields}
          renderedFieldsRef={renderedFieldsRef}
          selectedTextField={selectedTextField}
          selectedTextFieldRef={selectedTextFieldRef}
          highlights={highlights}
          highlightsRef={highlightsRef}
          pdfViewerWidth={pdfViewerWidth}
          viewLines={viewLines}
          tipOriginalValue={tipOriginalValue}
          fieldTrustScoresRef={fieldTrustScoresRef}
          handleChangeMain={handleChangeMain}
          setSelectedTextField={setSelectedTextField}
          handleChangeVatEsLine={handleChangeVatEsLine}
          handleChangeLines={handleChangeLines}
          setHighlights={setHighlights}
          handleLinesOnBlur={handleLinesOnBlur}
          setFieldTrustScores={setFieldTrustScores}
          handleLinesDataForReprocessingChange={handleLinesDataForReprocessingChange}
          setLoading={(type:boolean)=>{
            setLoading(type)
          }}
          setTipOriginalValue={setTipOriginalValue}
          revertTipText={revertTipText}
        />
        {viewLines && (
          <LinesComponent
            refObj={linesRef}
            viewerWidth={pdfViewerWidthForLines}
            lineFields={lineFields}
            lineDetails={lineDetails}
            supplier={supplier}
            decimalSeparator={decimalSeparator}
            projects={projects}
            selectedTextField={selectedTextField}
            linesDataForReprocessing={linesDataForReprocessing}
            reprocessingLines={reprocessingLines}
            handleChange={handleChangeLines}
            handleOnBlur={handleLinesOnBlur}
            onAddLineConfirm={onAddLineConfirm}
            onDeleteLineConfirm={onDeleteLineConfirm}
            adjustLinesOrder={adjustLinesOrder}
            handleOpenManageFields={handleOpenManageFields}
            closeLinesComponent={closeLinesComponent}
            setSelectedTextField={setSelectedTextField}
            resetLineBoundaries={resetLineBoundaries}
            sendLinesForReprocessing={sendLinesForReprocessing}
          />
        )}
      </Grid>
      <InfoModal
        open={showInfoModal}
        document={doc}
        handleClose={closeInfoModal}
      />
      <DeleteDocumentModal
        open={openDeleteConf}
        id={doc.id}
        handleClose={onCancelDelete}
      />
      <ChooseValidatorModal
        open={chooseValidatorOpen}
        users={users}
        selectedValidator={selectedValidator}
        assistantID={doc.assistantID || '0'}
        handleClose={handleChooseValidatorModalClose}
        setSelectedValidator={setSelectedValidator}
        handleStatusChange={handleStatusChange}
      />
    </Grid>
  );
}

export default DocumentEdit;
