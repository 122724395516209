import React, { useEffect, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { Box, Button, ClickAwayListener, Grow, MenuItem, MenuList, Paper, Popper, Tooltip, Typography } from '@mui/material';
import {
  Email as SendToEmailIcon,
  SimCardDownload as ExportIcon,
  Inventory as ArchiveIcon,
  Download as DownloadIcon,
  DeleteOutline as DeleteIcon,
} from '@mui/icons-material';

import AssignUserIcon from 'src/dashboard-new/icons/AssignUserIcon';
import ChangeStatusIcon from 'src/dashboard-new/icons/ChangeStatusIcon';
import useWidth from 'src/hooks/useWidth';
import { useAuth } from 'src/hooks/useAuth';
import AssignUserModal from 'src/documents-new/components/AssignUserModal/AssignUserModal';
import { SelectedDocType, UserDataType } from 'src/types';
import { getDocumentStatusLabel, invoiceStatusCodesList, getStatusIcon } from 'src/dashboard-new/utils';
import { isStatusChangeValid } from 'src/utils/invoiceStateChangeValidations';
import {
  getInvoiceStatusForDropdown, getPermissionByInvoiceStatus, i18nByStatus, isActionPermitted, permissions
} from 'src/utils/helpers';
import styles from './style';

interface PropTypes {
  selectedDocs: SelectedDocType[];
  loading: boolean;
  companyFilterApplied: boolean;
  users: UserDataType[];
  refreshDocuments: () => void;
  setSelectedDocs: React.Dispatch<React.SetStateAction<SelectedDocType[]>>;
  handleChangeBulkStatus: (_to: number) => void;
  setSendEmailModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setExportDataModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  downloadInvoicesBulk: () => Promise<void>;
  handleBulkDelete: () => Promise<void>;
}

const BulkOperations = (props: PropTypes) => {
  const {
    selectedDocs,
    loading,
    companyFilterApplied,
    users,
    refreshDocuments,
    setSelectedDocs,
    handleChangeBulkStatus,
    setSendEmailModalOpen,
    setExportDataModalOpen,
    downloadInvoicesBulk,
    handleBulkDelete,
  } = props;
  const { ready, t } = useTranslation();
  const { user } = useAuth();

  const isToValidateStatusAllowed = user?.customisations.includes('toValidateStatus') || false;
  const isCanDelete = user?.customisations.includes('allowDelete') || false;

  const status = selectedDocs[0].status;

  const [openAssignUserModal, setOpenAssignUserModal] = useState(false);
  const [statusMenuOpen, setStatusMenuOpen] = useState(false);
  const statusMenuRef = useRef(null);
  const statusChangeBtnWidth = useWidth(statusMenuRef, status);

  const handleStatusMenuToggle = () => {
    setStatusMenuOpen(!statusMenuOpen);
  };

  const handleStatusMenuClose = () => {
    setStatusMenuOpen(false);
  };

  const isBulkActionChangeBtnDisabled = (status: number) => selectedDocs.some((doc) => (doc.status !== status));

  const handleBulkStatusChangeClick = (to: number) => {
    handleStatusMenuClose();
    handleChangeBulkStatus(to);
  };

  const isExportButtonDisabled = () => {
    if (selectedDocs.length === 0) {
      return true;
    }
    return selectedDocs.some((doc) => (doc.status !== 502 && doc.status !== 503));
  };

  const isChangeValid = (to: number) => {
    for (let i = 0; i < selectedDocs.length; i++) {
      if (!isStatusChangeValid(selectedDocs[i].status, to, isToValidateStatusAllowed)) {
        return false;
      }
    }

    return true;
  };

  useEffect(() => handleStatusMenuClose(), [selectedDocs]);

  return (
    <>
      <Box sx={styles.root}>
        <Typography sx={styles.selectedCount}>
          {t('BULK_OPERATIONS_SELECTED_COUNT', { count: selectedDocs.length })}
        </Typography>
        <Button
          sx={styles.actionBtn}
          onClick={() => setOpenAssignUserModal(true)}
          startIcon={<AssignUserIcon />}
        >
          {t('BULK_OPERATIONS_ASSIGN')}
        </Button>
        <Tooltip title={isBulkActionChangeBtnDisabled(status)
          ? ready && t('DASHBOARD_INVOICES_BULK_INVALID_STATUS')
          : !isActionPermitted(getPermissionByInvoiceStatus(status), user?.permissions)
            ? `INVOICE_STATUS_CHANGE_TOOLTIP_NO_PERMISSION_${i18nByStatus(status)}`
            : ''}
        >
          <span>
            <Button
              ref={statusMenuRef}
              sx={styles.actionBtn}
              onClick={handleStatusMenuToggle}
              disabled={!isActionPermitted(getPermissionByInvoiceStatus(status), user?.permissions) || isBulkActionChangeBtnDisabled(status)}
              startIcon={<ChangeStatusIcon />}
            >
              {t('DOCUMENT_HEADER_CHANGE_STATUS')}
            </Button>
          </span>
        </Tooltip>
        <Popper
          open={statusMenuOpen}
          anchorEl={statusMenuRef?.current}
          role={undefined}
          transition
          sx={{ zIndex: 111111, minWidth: statusChangeBtnWidth, boxShadow: '0px -4px 8px -4px #4C4E646B' }}
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
              }}
            >
              <Paper>
                <ClickAwayListener onClickAway={handleStatusMenuClose}>
                  <MenuList id="status-change-menu" autoFocusItem>
                    <MenuItem sx={styles.changeStatusLabel}>{t('DOCUMENT_HEADER_CHANGE_STATUS')}</MenuItem>
                    {
                      invoiceStatusCodesList.map((s) => {
                        if (
                          getDocumentStatusLabel(status) === getDocumentStatusLabel(s)
                          || !isStatusChangeValid(status, s, isToValidateStatusAllowed)
                          || (s === 900 && !isCanDelete && status !== 200 && status !== 201)
                        ) {
                          return null;
                        }
                        return (
                          <MenuItem
                            key={s}
                            onClick={() => handleBulkStatusChangeClick(s)}
                            sx={styles.changeStatusOptions}
                          >
                            <Box sx={styles.changeStatusIcon}>
                              {getStatusIcon(s)}
                            </Box>
                            {ready && t(getInvoiceStatusForDropdown(s))}
                          </MenuItem>
                        );
                      })
                    }
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
        {!user?.isDocunecta && (
          <Tooltip
            title={!isActionPermitted(permissions.invoiceExport, user?.permissions)
              ? ready && t('INVOICE_EXPORT_TOOLTIP_NO_PERMISSION')
              : companyFilterApplied
                ? ready && t('DASHBOARD_INVOICES_EXPORT_DISABLED_ANOTHER_COMPANY')
                : isExportButtonDisabled()
                  ? ready && t('INVOICE_EXPORT_TOOLTIP_INVALID_STATUS')
                  : ''}
          >
            <span>
              <Button
                sx={styles.actionBtn}
                disabled={companyFilterApplied || isExportButtonDisabled() || !isActionPermitted(permissions.invoiceExport, user?.permissions)}
                onClick={() => setSendEmailModalOpen(true)}
                startIcon={<SendToEmailIcon sx={styles.actionIcon} />}
              >
                {t('INVOICE_SEND_TO_EMAIL_BTN')}
              </Button>
            </span>
          </Tooltip>
        )}
        <Tooltip
          title={!isActionPermitted(permissions.invoiceExport, user?.permissions)
            ? ready && t('INVOICE_EXPORT_TOOLTIP_NO_PERMISSION')
            : companyFilterApplied
              ? ready && t('DASHBOARD_INVOICES_EXPORT_DISABLED_ANOTHER_COMPANY')
              : isExportButtonDisabled()
                ? ready && t('INVOICE_EXPORT_TOOLTIP_INVALID_STATUS')
                : ''}
        >
          <span>
            <Button
              sx={styles.actionBtn}
              disabled={companyFilterApplied || isExportButtonDisabled() || !isActionPermitted(permissions.invoiceExport, user?.permissions)}
              onClick={() => setExportDataModalOpen(true)}
              startIcon={<ExportIcon sx={styles.actionIcon} />}
            >
              {t('INVOICE_EXPORT_BTN')}
            </Button>
          </span>
        </Tooltip>
        <Tooltip
          title={ready && t(isChangeValid(600) ? 'DASHBOARD_INVOICES_ARCHIEVE_TOOLTIP' : 'DASHBOARD_INVOICES_ARCHIEVE_TOOLTIP_DISABLED')}
        >
          <span>
            <Button
              sx={styles.actionBtn}
              disabled={!isChangeValid(600) || !isActionPermitted(permissions.invoiceArchive, user?.permissions) || loading}
              onClick={isChangeValid(600) ? () => handleChangeBulkStatus(600) : () => {}}
              startIcon={<ArchiveIcon sx={styles.actionIcon} />}
            >
              {t('BULK_OPERATIONS_ARCHIVE')}
            </Button>
          </span>
        </Tooltip>
        <Tooltip
          title={ready && t(selectedDocs.some((doc) => (doc.status === 200 || doc.status === 201))
            ? 'DASHBOARD_INVOICES_DOWNLOAD_DISABLED_TOOLTIP'
            : 'DASHBOARD_INVOICES_DOWNLOAD_TOOLTIP')}
        >
          <span>
            <Button
              sx={styles.actionBtn}
              onClick={downloadInvoicesBulk}
              disabled={companyFilterApplied || selectedDocs.some((doc) => (doc.status === 200 || doc.status === 201)) || loading}
              startIcon={<DownloadIcon sx={styles.actionIcon} />}
            >
              {t('BULK_OPERATIONS_DOWNLOAD')}
            </Button>
          </span>
        </Tooltip>
        <Tooltip
          title={!isChangeValid(900) ? t('INVOICE_STATUS_CHANGE_DELETE_INVALID')
            : !isActionPermitted(permissions.invoiceArchive, user?.permissions)
              ? ready && t('INVOICE_STATUS_CHANGE_TOOLTIP_NO_PERMISSION_DELETE')
              : ready && t('INVOICE_STATUS_CHANGE_DELETE')}
        >
          <span>
            <Button
              sx={styles.actionBtn}
              disabled={companyFilterApplied || !isChangeValid(900) || !isActionPermitted(permissions.invoiceDelete, user?.permissions) || loading}
              onClick={isChangeValid(900) ? () => handleBulkDelete() : () => {}}
              startIcon={<DeleteIcon sx={styles.actionIcon} />}
            >
              {t('INVOICE_DUPLICATE_DELETE')}
            </Button>
          </span>
        </Tooltip>
      </Box>
      <AssignUserModal
        open={openAssignUserModal}
        selectedDocs={selectedDocs}
        loading={loading}
        companyFilterApplied={companyFilterApplied}
        users={users}
        handleClose={() => setOpenAssignUserModal(false)}
        refreshDocuments={refreshDocuments}
        setSelectedDocs={setSelectedDocs}
      />
    </>
  );
};

export default BulkOperations;