import { useRef } from 'react';
import { useTranslation } from 'react-i18next';

import {
  Box, Button, CircularProgress, ClickAwayListener, Grow, MenuItem, MenuList, Paper, Popper, Tooltip, Typography
} from '@mui/material';
import {
  ArrowDropDown as ArrowDropDownIcon,
} from '@mui/icons-material';

import {
  getInvoiceStatusForDropdown, getPermissionByInvoiceStatus, i18nByStatus, isActionPermitted
} from 'src/utils/helpers';
import { isStatusChangeValid } from 'src/utils/invoiceStateChangeValidations';
import { useAuth } from 'src/hooks/useAuth';
import useWidth from 'src/hooks/useWidth';
import { DocType } from 'src/types';
import { getDocumentStatusLabel, invoiceStatusCodesList, getStatusIcon } from 'src/dashboard-new/utils';
import styles from './style';

interface PropTypes {
  statusChangeLoading: boolean;
  doc: DocType;
  documentStatus: number;
  statusMenuOpen: boolean;
  handleStatusMenuToggle: (_id: number, _asstID?: string) => void;
  handleStatusMenuClose: () => void;
  handleStatusChange: (_status: number, _asstID: string, _owner?: string) => void;
}

const StatusChangeBtn = (props: PropTypes) => {
  const { user } = useAuth();
  const { ready, t } = useTranslation();

  const isToValidateStatusAllowed = user?.customisations.includes('toValidateStatus') || false;
  const isCanDelete = user?.customisations.includes('allowDelete') || false;

  const {
    statusChangeLoading,
    doc,
    documentStatus,
    statusMenuOpen,
    handleStatusMenuToggle: _handleStatusMenuToggle,
    handleStatusMenuClose,
    handleStatusChange
  } = props;

  const statusMenuRef = useRef(null);
  const statusChangeBtnWidth = useWidth(statusMenuRef, documentStatus);

  const isStatusChangeable = (status: number) => {
    if (status === 200 || status === 201 || status === 999) {
      return false;
    }
    return true;
  };

  const handleStatusMenuToggle = (doc: DocType) => {
    if (doc.status === 100) {
      return;
    }
    _handleStatusMenuToggle(doc.id, doc.assistantID);
  };

  if (statusChangeLoading) {
    return (
      <Box sx={styles.action}>
        <CircularProgress size={20} />
      </Box>
    );
  }

  return (
    <>
      <Tooltip title={!isActionPermitted(getPermissionByInvoiceStatus(documentStatus), user?.permissions)
        ? t(`INVOICE_STATUS_CHANGE_TOOLTIP_NO_PERMISSION_${i18nByStatus(documentStatus)}`)
        : doc.outOfLimit
          ? t(user?.affiliate ? 'INVOICE_STATUS_OUT_OF_LIMIT_AFFILIATE_TOOLTIP'
            : 'INVOICE_STATUS_OUT_OF_LIMIT_TOOLTIP'): ''}
      >
        <div>
          <Button
            ref={statusMenuRef}
            sx={styles.statusChangeBtn}
            variant="contained"
            onClick={() => isStatusChangeable(doc.outOfLimit ? 999 : documentStatus) && handleStatusMenuToggle(doc)}
            disabled={!isActionPermitted(getPermissionByInvoiceStatus(documentStatus), user?.permissions)}
            disableElevation={doc.outOfLimit}
            disableTouchRipple={doc.outOfLimit}
            startIcon={getStatusIcon(doc.outOfLimit ? 999 : documentStatus)}
          >
            <Typography sx={styles.statusChangeText}>
              {ready && t(getDocumentStatusLabel(doc.outOfLimit ? 999 : documentStatus))}
              {isStatusChangeable(doc.outOfLimit ? 999 : documentStatus) && <ArrowDropDownIcon sx={styles.dropDownIcon} />}
            </Typography>
          </Button>
        </div>
      </Tooltip>
      <Popper
        open={statusMenuOpen}
        anchorEl={statusMenuRef?.current}
        role={undefined}
        transition
        sx={{ zIndex: 111111, minWidth: statusChangeBtnWidth, boxShadow: '0px -4px 8px -4px #4C4E646B', }}
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleStatusMenuClose}>
                <MenuList id="status-change-menu" autoFocusItem>
                  <MenuItem sx={styles.changeStatusLabel}>{t('DOCUMENT_HEADER_CHANGE_STATUS')}</MenuItem>
                  {
                    invoiceStatusCodesList.map((s) => {
                      if (
                        getDocumentStatusLabel(documentStatus) === getDocumentStatusLabel(s)
                          || !isStatusChangeValid(documentStatus, s, isToValidateStatusAllowed)
                          || (s === 900 && !isCanDelete && documentStatus !== 200 && documentStatus !== 201)
                      ) {
                        return null;
                      }
                      return (
                        <MenuItem
                          key={s}
                          onClick={() => handleStatusChange(s, doc.assistantID || '0', doc.owner)}
                          sx={styles.changeStatusOptions}
                        >
                          <Box sx={styles.changeStatusIcon}>
                            {getStatusIcon(s)}
                          </Box>
                          {ready && t(getInvoiceStatusForDropdown(s))}
                        </MenuItem>
                      );
                    })
                  }
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
}

export default StatusChangeBtn;
